import { DatePipe, ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Product } from '../classes/product';
import { IIndexOrder } from '../Models/IOrder';
import { IUser } from '../Models/IUser';
import { IExtendPagination, PaginatedResult } from '../Pagination/IPagination';
import { AuthService } from './account.service';
import { CategoryService } from './Category.service';
import { CustomerService } from './Customer.service';
import { HomeService } from './home.service';
import { MarketService, IMarket } from './market.service';
import { IMenu, NavService } from './nav.service';
import { OrderService } from './order.service';
import { ProductService } from './product.service';
import { ShippingService } from './shipping.service';

@Injectable({
  providedIn: 'root'
})
export class Resolver implements Resolve<Product> {

  public product: Product = {};

  constructor(
    private router: Router,
    public productService: ProductService
  ) { }

  // Resolver
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.productService.getProductBySlug(route.params.slug).subscribe(product => {
      if (!product) { // When product is empty redirect 404
        this.router.navigateByUrl('/pages/404', { skipLocationChange: true });
      } else {
        this.product = product
      }
    })
    return this.product;
  }
}




@Injectable({
  providedIn: 'root',
})
export class NavResolver implements Resolve<any> {
  constructor(
    private _navService: NavService,
    private _authService: AuthService,
    private _homeService: HomeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {



    let user: IUser = JSON.parse(localStorage.getItem('user'));
    this._authService.check().subscribe(value => {
      if (value === true) {


        return forkJoin([

          this._homeService.baseItems("CartItem"),
          this._homeService.loadTopCollections(user.id)
          // this._homeService.baseItems("CompareItem"),
        ])


      }
    })

    return forkJoin([
      this._navService.loadMenus(),
      this._homeService.loadSliderImages(),
      this._homeService.loadTopCollectionsSlide(),
      this._homeService.loadTopCollections()
    ])






    // if(user && checkLoginStatus)
    // {

    //   return forkJoin([

    //     this._homeService.baseItems("CartItem"),
    //     this._navService.loadMenus(),
    //     this._homeService.loadSliderImages(),
    //     this._homeService.loadTopCollectionsSlide(),
    //     this._homeService.loadTopCollections(user.id)
    //     // this._homeService.baseItems("CompareItem"),
    //     ])
    // } 
    // else {
    //   return forkJoin([
    //     this._navService.loadMenus(),
    //     this._homeService.loadSliderImages(),
    //     this._homeService.loadTopCollectionsSlide(),
    //     this._homeService.loadTopCollections()
    //     ])
    // }
  }
}


@Injectable({
  providedIn: 'root',
})
export class BrandCollectionResolver implements Resolve<any> {
  constructor(
    private _navSercice: NavService,
    private _router: Router,
    private viewScroller: ViewportScroller,
    private _activatedRoute: ActivatedRoute,
    private _categoryService: CategoryService,
    private _homeService: HomeService) { }


  _categoryQuery$: Observable<string>;
  _subCategoryQuery$: Observable<string>;
  _brandQuery$: Observable<string>;


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {

    // const params = route.data['params'];

    let categoryName = '';
    let subCategoryName = '';
    let brandName = '';

    // let slug = route.paramMap.get('slug');
    // let getSlug = slug.substring(0,7);
    // if(getSlug=='noValue')
    // {
    //   let getType = slug.substring(7,13);


    //   if(getType =='CParam')
    //   {
    //     categoryName = slug.substring(13)
    //   }else if(getType =="SParam")
    //   {
    //     subCategoryName = slug.substring(13)
    //   }else if(getType =="BParam")
    //   {
    //     brandName =slug.substring(13)
    //   }
    //   slug = getSlug;
    // }



    this._categoryQuery$ = this._categoryService.getCategoryQuery$;
    this._categoryQuery$.subscribe(category => {
      categoryName = category
    });

    this._subCategoryQuery$ = this._categoryService.getSubCategoryQuery$;
    this._subCategoryQuery$.subscribe(sub => {
      subCategoryName = sub;
    })

    this._brandQuery$ = this._categoryService.getBrandQuery$;
    this._brandQuery$
      .subscribe(brand => {
        brandName = brand;
      })



    const pageData = route.data['params'];


    const pagination: IExtendPagination = {
      currentPage: +pageData.currentPage,
      pageSize: +pageData.pageSize,
      category: categoryName,
      subCategory: subCategoryName,
      brand: brandName
    };


    return forkJoin([
      this._homeService.searchBrands(pagination),
      this._homeService.loadCategoriesCollection(),
      this._homeService.loadSubCategoriesCollection(-1, subCategoryName)
    ])
  }
}




@Injectable({
  providedIn: 'root',
})
export class BrandCollectionByIdResolver implements Resolve<any> {
  constructor(private _homeService: HomeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {

    const barcode = route.paramMap.get('barcode');


    return forkJoin(
      [
        this._homeService.topCollectionById(barcode),
        this._homeService.salesProductList()
      ]
    )
    //return this._homeService.topCollectionById(barcode)
  }
}




@Injectable({
  providedIn: 'root',
})
export class DinarCurrencyResolver implements Resolve<any> {
  constructor(private _customerService: CustomerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {

    return this._customerService.dinarRate();
  }
}



@Injectable({
  providedIn: 'root',
})
export class orderByIdResolver implements Resolve<any> {
  constructor(private _orderService: OrderService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {
    const orderId = route.paramMap.get('orderId');

    return this._orderService.orderWithItemsByIdInServer(orderId);
  }
}



@Injectable({
  providedIn: 'root',
})

export class OrdersListOfUserResolver implements Resolve<any> {
  constructor(
    private _orderService: OrderService,
    private _router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedResult<IIndexOrder[]>> {

    const params = route.data['params'];
    const pagination: IExtendPagination = {
      currentPage: +params.currentPage,
      pageSize: +params.pageSize
    };

    let buyer: IUser = JSON.parse(localStorage.getItem('user'));
    if (!buyer) {
      return;
    }

    return this._orderService.ordersListoFBuyer(buyer.id, pagination);
  }
}




@Injectable({
  providedIn: 'root',
})
export class WishlistResolver implements Resolve<any> {
  constructor(
    private _navSercice: NavService,
    private _homeService: HomeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {



    let user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return this._homeService.baseItems("WishListItem");
    }
    return null;
  }
}




@Injectable({
  providedIn: 'root',
})
export class shippingByIdResolver implements Resolve<any> {
  constructor(private _shippingService: ShippingService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {
    const buyerId = route.paramMap.get('buyerId');
    const shippingId = +route.paramMap.get('shippingId');


    return this._shippingService.shippingIdAsync(buyerId, shippingId);
  }
}




@Injectable({
  providedIn: 'root',
})
export class MarketsResolver implements Resolve<any> {
  constructor(private _marketService: MarketService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<IMarket[]> {

    return this._marketService.loadMarkets();
  }
}