/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from './IPagination';

export function getPaginationResult<T>(url: string, params: HttpParams,http: HttpClient): Observable<PaginatedResult<T>> {
  const  paginationResult: PaginatedResult<T> = new PaginatedResult<T>();

    return http.get<T>(url, { observe: 'response', params }).pipe(
      map((response: any) => {

        paginationResult.result = response.body;
        if (response.headers.get('X-Pagination') != null) {
          paginationResult.pagination = JSON.parse(response.headers.get('X-Pagination'));
        }
        return paginationResult;
      })
    );
  }


  export function getPaginationHeaders(pageNumber: number=1, pageSize: number=10){

    let params = new HttpParams();

      params = params.append('currentPage',pageNumber.toString());
      params = params.append('pageSize',pageSize.toString());

      return params;
  }


