import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../classes/product';
import { ICategoryCollection } from '../../Models/ICategories';
import { IExtendPagination } from '../../Pagination/IPagination';
import { HomeService } from '../../services/home.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public categoryCollection$: Observable<ICategoryCollection[]>;
  public categoryCollection: ICategoryCollection[] =[];


  @Output() pagination: EventEmitter<IExtendPagination> = new EventEmitter<IExtendPagination>();
  @Output() categoryName: EventEmitter<string> = new EventEmitter<string>();

  constructor(public productService: ProductService, public _homeService: HomeService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.categoryCollection$ = this._homeService.getCategoryCollection$;
    this.categoryCollection$.subscribe(value=>{      

      if(!value)
      {
        this._homeService.loadCategoriesCollection().subscribe(data=>{
          this.categoryCollection = data;
        });
      }else {
        this.categoryCollection = value;

      }

    })
  }


  OnFilterCategory(categoryId: number,  categoryName: string)
  {
    
  this.categoryName.emit(categoryName);
  // this.pagination.emit(paginationData);

  //this._homeService.searchBrands('noValue',paginationData).subscribe();
  this._homeService.loadSubCategoriesCollection(categoryId,'--').subscribe();
  this._homeService.resetSubBrandCollection();

  }


}
