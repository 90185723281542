import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../Models/IUser';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    
    var price = args.unitPrice;
    var user:IUser =JSON.parse(localStorage.getItem('user'));
    var isWholeSaleAccount = false;
    // const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price;
    if(user)
    {
       isWholeSaleAccount = user.isWholeSaleAccount.toString()==="True";

    }


    if(args.wholeSalePrice>0 && isWholeSaleAccount === true)
    {
      price = args.wholeSalePrice;
    } 
    else if(args.newPrice>0)
    {
      price = args.newPrice;
    }
    return price
    



    // const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price;
    // const price = args.newPrice ? args.newPrice  : args.unitPrice;
    // return price
  }

}



@Pipe({
  name: 'discountText'
})
export class DiscountTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    
    var text = "";
    var user:IUser =JSON.parse(localStorage.getItem('user'));
    var isWholeSaleAccount = false;
    // const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price;
    if(user)
    {
       isWholeSaleAccount = user.isWholeSaleAccount.toString()==="True";

    }
    var isInKurdishLanguage = localStorage.getItem('code');
    if(args.wholeSalePrice>0 && isWholeSaleAccount === true)
    {

        text =isInKurdishLanguage?"نرخی جومله‌": "Wholesale";


    } 
    else if(args.newPrice>0)
    {
      text = isInKurdishLanguage?"داشكان":"Sale";
    }
    return text
    



    // const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price;
    // const price = args.newPrice ? args.newPrice  : args.unitPrice;
    // return price
  }

}


@Pipe({
  name: 'wholseSale'
})
export class WholeSalwPipe implements PipeTransform {

  transform(value: any, args?: any): any {

  

    var price = args.unitPrice;
    var user:IUser =JSON.parse(localStorage.getItem('user'));

    // const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price;
    var isWholeSaleAccount = user.isWholeSaleAccount.toString()==="True";

    if(args.wholeSalePrice>0 && isWholeSaleAccount === true)
    {
      price = args.wholeSalePrice;
    } 
    else if(args.newPrice>0)
    {
      price = args.newPrice;
    }
    return price
    


  }

}
