import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ShopComponent } from "src/app/shop/shop.component";
import { environment } from "src/environments/environment";
import { IIndexOrder } from "../Models/IOrder";
import {
  IIndexShipping,
  IShipping,
  IShippingConfirm,
  ShippingStatus,
} from "../Models/IShipping";
import {
  getPaginationHeaders,
  getPaginationResult,
} from "../Pagination/getPaginationResult";
import { IExtendPagination, PaginatedResult } from "../Pagination/IPagination";
import * as moment from "moment";

const state = {
  checkoutItems: JSON.parse(localStorage["checkoutItems"] || "[]"),
};

export interface IMarket {
  marketId: number;
  domain: string;
  imageUrl: string;
}


@Injectable({
  providedIn: "root",
})
export class MarketService {

  private baseUrl = `${environment.api}/Markets`;

  public _markets$: BehaviorSubject<IMarket[] | null> = new BehaviorSubject(null);


  get getMarkets$(): Observable<IMarket[]> {
    return this._markets$.asObservable();
  }



  constructor(private _httpClient: HttpClient) { }



  loadMarkets(): Observable<IMarket[]> {
    return this._httpClient.get<IMarket[]>(`${this.baseUrl}`)
      .pipe(
        map((m) => {
          this._markets$.next(m);
          return m;
        })
      );
  }



}
