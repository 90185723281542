<div class="icon-nav">
    <ul>

        <li class="onhover-div" style="margin-top: -15px;">
            <a [routerLink]="['/shop/usaMarkets']">
                <img src="assets/images/icon/shipping.png" style="height: 35px;" class="img-fluid" alt="online Market">
            </a>
        </li>

        <li class="onhover-div" style="margin-top: -15px;">
            <a [routerLink]="['/shop/onlineMarket']">
                <img src="assets/images/icon/order.png" style="height: 35px;" class="img-fluid" alt="New Order">
            </a>
        </li>

        <li class="onhover-div" style="margin-top: -15px;">
            <a (click)="onChangeEventFunc()">
                <img src="assets/images/icon/wholesale.png" style="height: 25px;" class="img-fluid" alt="Wholesale">
            </a>
        </li>


        <li class="onhover-div mobile-search search-widgets" id="search-widgets">
            <div (click)="searchToggle()">
                <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                <i class="ti-search"></i>
            </div>
            <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                <div>
                    <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                    <div class="overlay-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <form>
                                        <div class="form-group">
                                            <input type="text" class="form-control" style="background: #1b185d;
                                                   border-radius: 10px 00px 10px 0px; 
                                                   padding-left: 20px;
                                                   margin-top: 5px; 
                                                   color: white;" #searchValue id="exampleInputPassword1"
                                                [formControl]="searchProductInputControl"
                                                placeholder="{{'Search a Product'|translate}}">
                                        </div>
                                        <button type="button" (click)="onSearch(searchValue.value)"
                                            style="color: white;" class="btn btn-primary"><i class="fa fa-search"
                                                style="color: white;">
                                            </i>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="onhover-div mobile-setting">
            <div><img [src]="onFlagLangauge()" style="height: 25px; width: 40px; border-radius: 4px;" class="img-fluid"
                    alt="">
                <i class="ti-settings"></i>
            </div>
            <div class="show-div setting">
                <h6>{{'Language'|translate}}</h6>
                <ul>
                    <li *ngFor="let language of languages">
                        <a (click)="changeLanguage(language.code)">{{ language.name=='English'?'English':'Kurdish' |
                            translate}}</a>
                    </li>
                </ul>
                <!-- <h6>currency</h6>
                <ul class="list-inline">
                    <li *ngFor="let currency of currencies">
                        <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
                    </li>
                </ul> -->
            </div>
        </li>



        <li class="onhover-div mobile-cart">

            <div>
                <a [routerLink]="['/shop/cart']">
                    <img src="assets/images/icon/cart.png" class="img-fluid" alt="" style="width: 26px;">
                    <i class="ti-shopping-cart"></i>
                </a>

            </div>
            <span class="cart_qty_cls" *ngIf="(cartItems$|async) as cartItems; else noCartItemLenth">{{
                cartItems.length}}</span>
            <ng-template #noCartItemLenth>
                <span class="cart_qty_cls" *ngIf="(cartItems$|async) as cartItems; else noCartItem">0</span>
            </ng-template>

            <ng-template #noCartItem>
                <ul class="show-div shopping-cart">
                    <h5>{{'Your cart is currently empty.'|translate}}</h5>
                </ul>
            </ng-template>


            <ul class="show-div shopping-cart" *ngIf="(cartItems$|async) as cartItems; else noCartItem ">
                <li *ngFor="let product of cartItems">
                    <div class="media">
                        <a [routerLink]="['/shop/product/left/sidebar/', product.barcode]">
                            <img class="mr-3" [src]="product.imageName">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/shop/product/left/sidebar/',  product.barcode]">
                                <h4>{{ product?.productName }}</h4>
                            </a>
                            <h4>
                                <span>
                                    {{ product?.quantity }} x
                                    {{ product?.unitPrice | discount:product |
                                    currency:productService?.Currency.currency:'symbol' }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <!-- <div class="close-circle" (click)="removeItem(product)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div> -->
                </li>
                <li>
                    <div class="total">
                        <!-- <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5> -->
                        <h5>{{'Sub Total'|translate}} : <span>{{ totalPrice |
                                currency:productService?.Currency.currency:'symbol' }}</span></h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a (click)="onOpenCart()" class="view-cart">{{'View Cart'|translate}}</a>
                        <a (click)="onOpenCheckout()" class="checkout">{{'Checkout'|translate}}</a>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>