/* eslint-disable @typescript-eslint/explicit-function-return-type */

export interface IPagination {
  currentPage: number;
  pageSize: number;

}

export interface IExtendPagination extends IPagination {
  totalCount?: number;
  totalPages?: number;
  orderBy?: string;
  searchTerm?: string;
  fromDate?: string;
  toDate?: string;
  id?: number;
  guidId?: string;
  category?: string;
  subCategory?: string;
  brand?: string;
  minPrice?: number;
  maxPrice?: number;
  tableName?: string;
  filtered?: string;
  buyerId?: string;
  wholesalerAccount?: number;
  customerId?: string;
  totalAmount?: number;

}

export class PaginatedResult<T>
{

  result: T;
  pagination: IExtendPagination;


}
