import { ViewportScroller } from "@angular/common";
import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IExtendPagination } from "../../Pagination/IPagination";
import { CategoryService } from "../../services/Category.service";
import { HomeService } from "../../services/home.service";
import { IMenu, NavService } from "../../services/nav.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() breadcrumb: string;

  @Input() category: string;

  @Input() subcategory: string;

  @Input() brand: string;
  pagination: IExtendPagination;

  // categoryName: string;
  // subCategryName: string;
  // brandName: string;

  constructor(
    private _router: Router,
    private _categoryService: CategoryService,
    private _homeService: HomeService,
    public _activatedRoute: ActivatedRoute,
    private _navService: NavService,
    private viewScroller: ViewportScroller
  ) {}

  ngOnInit(): void {}

  onCategoryClick() {
    this._categoryService.categoryQuery(this.category).subscribe();
    this._homeService.loadSubCategoriesByCategoryName(this.category)
      .subscribe();

    this._categoryService._subCategoryQuery$.next(null);
    this._categoryService._brandQuery$.next(null);
    this._categoryService._brandQuery$.next(null);

    this.updateProductFilter();

  }

  onSubcategoryClick() {

    this._categoryService.categoryQuery(this.category).subscribe();
    this._homeService.loadSubCategoriesByCategoryName(this.category).subscribe();

    this._categoryService.subCategoryQuery(this.subcategory).subscribe();
    this._homeService.loadsubBrandBySubCategoryName(this.subcategory).subscribe();

    this._categoryService._brandQuery$.next(null);

    this.updateProductFilter();
  }

  onBrandClick() {

    this._categoryService.categoryQuery(this.category).subscribe();
    this._homeService.loadSubCategoriesByCategoryName(this.category).subscribe();

    this._categoryService.subCategoryQuery(this.subcategory).subscribe();
    this._homeService.loadsubBrandBySubCategoryName(this.subcategory).subscribe();

    this._categoryService.brandQuery(this.brand).subscribe();

    this.updateProductFilter();
  }

  updateProductFilter() {
    let params = {
      category: this.category ? this.category : null,
      subCategory: this.subcategory ? this.subcategory : null,
      brand: this.brand ? this.brand : null,
    };

    this._router
      .navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: params,
        queryParamsHandling: "merge", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
      });

    this._router.navigateByUrl(`/shop/product`);
  }


  categoryTitle(title: string): string {
    var getTitle = title;
    var code = localStorage.getItem("code");
    if(!code)
    {
         
      return getTitle;
    }

    this._navService.getMenus$.subscribe((menus: IMenu[]) => {
      if (menus) {
        var code = localStorage.getItem("code");

        if (code) {
          menus.forEach((nav) => {

            if (nav.mainTitle == getTitle) {
              getTitle = nav.titleInKurdish?nav.titleInKurdish:nav.mainTitle;
              return getTitle;
            } 


          });
        }
      }else{
        this._navService.loadMenus().subscribe();
      }
    });

    return getTitle;
  }



  subCategoryTitle(title: string): string {

    var getTitle = title;
    var code = localStorage.getItem("code");
    if(!code)
    {         
      return getTitle;
    }

    this._navService.getMenus$.subscribe((menus: IMenu[]) => {
      if (menus) {
        var code = localStorage.getItem("code");

        if (code) {
          menus.forEach((nav) => {

            if(nav.children)
            {
              
              nav.children.forEach(subNav=>{



              if (subNav.mainTitle == getTitle) {
                getTitle = subNav.titleInKurdish?subNav.titleInKurdish:subNav.mainTitle;
                return getTitle;
              }



              })


            }

          });
        }
      }else{
        this._navService.loadMenus().subscribe();
      }
    });

    return getTitle;

  }



  brandCategoryTitle(title: string): string {

    
    var getTitle = title;
    var code = localStorage.getItem("code");
    if(!code)
    {         
      return getTitle;
    }

    this._navService.getMenus$.subscribe((menus: IMenu[]) => {
      if (menus) {
        var code = localStorage.getItem("code");

        if (code) {
          menus.forEach((nav) => {

            if(nav.children)
            {
              
              nav.children.forEach(subNav=>{


                if(subNav.children)
                {


                  subNav.children.forEach(brandNav=>{

                    
                    if (brandNav.mainTitle == getTitle) {
                      getTitle = brandNav.titleInKurdish?brandNav.titleInKurdish:brandNav.mainTitle;
                      return getTitle;
                    }

                  })




                }





              })


            }

          });
        }
      }else{
        this._navService.loadMenus().subscribe();
      }
    });

    return getTitle;

  }

}
