import { Component, PLATFORM_ID, Inject, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private facebookService: FacebookService,
    private loader: LoadingBarService,
    translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'ku']);
    }





  }
  ngAfterViewInit(): void {
    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) return;
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src =
    //     'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.2&appId=2804221893206672&autoLogAppEvents=1';
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, 'script', 'facebook-jssdk');
  }
  ngOnInit(): void {
    // this.initFacebookService();
  }

  private initFacebookService(): void {
    // const initParams: InitParams = { appId: '2804221893206672', xfbml: true, version: 'v3.2' };
    const initParams: InitParams = { appId: '', xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }



}
