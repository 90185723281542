import { Pipe, PipeTransform } from '@angular/core';
import { IMenu } from '../services/nav.service';

@Pipe({
  name: 'ToKurdishLanguage'
})
export class ToKurdishLanguagePipe implements PipeTransform {

  transform(value:IMenu): any {
    
    var getLanguageCode =localStorage.getItem('code');


    if(getLanguageCode == 'ku')
    {
      if(value.titleInKurdish)
      {
        return value.titleInKurdish;
      }
    }

    return value.title;
    
  }

}
