import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLength'
})
export class TextLengthPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    var result = value.length<30?value:value.substring(0,30);
    return result;
  }

}
