import { Component, OnInit } from '@angular/core';
import { NavService, IMenu } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IExtendPagination } from '../../Pagination/IPagination';
import { HomeService } from '../../services/home.service';
import { CategoryService } from '../../services/Category.service';
import { ICategoryCollection, ISubCategoryCollection } from '../../Models/ICategories';
import { Observable } from 'rxjs';
import { Product } from '../../classes/product';
import { IUsaProduct } from '../../Models/TopCollectionProduct';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: IMenu[];

  

  public categoryCollection$: Observable<ICategoryCollection[]>;
  public categoryCollection: ICategoryCollection[] = [];
  
  
  public subCategoryCollection$: Observable<ISubCategoryCollection[]>;
  public subCategoryCollection: ISubCategoryCollection[] =[];
  
  public products: Product[] = [];
  public brandCollectionList: IUsaProduct[] = [];



  pagination: IExtendPagination;


  
  categoryName: string;
  subCategoryName: string;
  brandName: string;



  constructor(private _router: Router, 
    public _activatedRoute: ActivatedRoute,
    public navServices: NavService,
    private viewScroller: ViewportScroller,
    private _categoryService: CategoryService,
    private _homeService: HomeService
    ) {

    this.navServices.getMenus$.subscribe(menuItems => 
      {
        if(!menuItems)
        {
          this.navServices.loadMenus().subscribe();
        }
        
        this.menuItems = menuItems 
      }
      );

    this._router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });


  }

  ngOnInit(): void {
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }


    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
      item.active = !item.active;
    }
  
    onOpen(item: IMenu)
    {
      // this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
      item.active = !item.active;

    }

  // Click Toggle menu (Mobile)
  CategoryNavigation(item: IMenu) {
    
    item.active = !item.active;

    this.categoryName = item.mainTitle;
    this.subCategoryName ='';
    this.brandName=''

    this._categoryService.categoryQuery(this.categoryName).subscribe();
    this._homeService.loadSubCategoriesByCategoryName(this.categoryName).subscribe();

    this._categoryService._subCategoryQuery$.next(null);
    this._categoryService._brandQuery$.next(null);

    this.updateProductFilter();
  }


    // Click Toggle menu (Mobile)
    subCategoryNavigation(subcategory: string, item: any) {



      this.categoryName = item.mainTitle;
      this.subCategoryName = subcategory;
      this.brandName=''

      this._categoryService.categoryQuery(this.categoryName).subscribe();
      this._homeService.loadSubCategoriesByCategoryName(this.categoryName).subscribe();

      this._categoryService.subCategoryQuery(this.subCategoryName).subscribe();
      this._homeService.loadsubBrandBySubCategoryName(this.subCategoryName).subscribe();     

      this._categoryService._brandQuery$.next(null);

      this.updateProductFilter();
    }

    // Click Toggle menu (Mobile)
    brandCategoryNavigation(brand: string,subcategory:any, category: any) {

      this.brandName = brand;

      this.categoryName = category.mainTitle;;
      this.subCategoryName =subcategory.mainTitle;


      this._categoryService.categoryQuery(this.categoryName).subscribe();
      this._homeService.loadSubCategoriesByCategoryName(this.categoryName).subscribe();

      this._categoryService.subCategoryQuery(this.subCategoryName).subscribe();
      this._homeService.loadsubBrandBySubCategoryName(this.subCategoryName).subscribe();     

      this._categoryService.brandQuery(this.brandName).subscribe();

      this.updateProductFilter();
    }


    
  updateProductFilter() {
    
    let params = { 
      category:this.categoryName?this.categoryName:null, 
      subCategory:this.subCategoryName?this.subCategoryName:null, 
      brand:this.brandName?this.brandName:null, 
    }

 

     this._router.navigate([], { 
       relativeTo: this._activatedRoute,
       queryParams: params,
       queryParamsHandling: 'merge', // preserve the existing query params in the route
       skipLocationChange: false  // do trigger navigation
     }).finally(() => {
       this.viewScroller.setOffset([120, 120]);
       this.viewScroller.scrollToAnchor('products'); // Anchore Link
     });


     this._router.navigateByUrl(`/shop/product`);      

  }

}
