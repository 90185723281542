<!-- <div *ngIf="product">
    <pre>
        {{product | json}}
    </pre>
</div> -->

<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block">
            <span class="lable3" *ngIf="product.new">new</span>
            <!-- <span class="lable4" *ngIf="product.sale">on sale</span> -->
        </div>
        <div style=" background: #FFFFFF;">
            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.productName.replace(' ', '-')]"> -->
            <a [routerLink]="['/shop/product/left/sidebar',product.barcode]" href="#">
                <!-- <img #image [defaultImage]="'assets/images/product/giphy.gif'" [lazyLoad]="product.images[0].src" class="img-fluid lazy-loading" [ngClass]="product.images[0].src?'cropped':''" alt="{{ product.images[0].alt }}" /> -->
                <img #image [defaultImage]="'assets/images/product/giphy.gif'" [lazyLoad]="product.imageName"
                    class="img-fluid lazy-loading cropped" [ngClass]="product.imageName" />
            </a>
        </div>
        <!-- <div class="back" *ngIf="onHowerChangeImage">
            <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
            </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail">
            <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
                    <img [lazyLoad]="image.src">
                </a>
            </li>
        </ul> -->
        <div class="cart-info cart-wrap" style="background:  rgba(142, 168, 100, 0.3); border-radius: 10px; ">
            <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a> -->
            <a title="Add to cart" (click)="addToCart(product)">
                <i class="ti-shopping-cart" style="font-weight: bold;"></i>
            </a>
            <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <!-- <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a> -->
        </div>
    </div>
    <div class="product-detail" style="margin-top: 30px;">
        <div>
            <!-- <bar-rating [rate]="2" [readOnly]="true" required="false"></bar-rating> -->
            <a>
                <h6>{{ product.productName }}</h6>
            </a>


            <h4>
                {{ product?.unitPrice | discount:product | currency:currency?.currency:'symbol' }}
                <h6 *ngIf="isWholeSaleAccount =='True'" [ngStyle]="kurdishFormat()"
                    style="font-weight: bold; color: green;">{{""|discountText:product}}</h6>
                <del *ngIf="product.wholeSalePrice>0 || product.newPrice>0"><span class="money"
                        style=" font-weight: bold; color: brown;"> {{ product?.unitPrice |
                        currency:currency?.currency:'symbol' }}</span></del>
            </h4>



            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]"> -->

            <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
                <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                </li>
            </ul> -->

            <!-- <pre>
                {{product.productVariations |json}}
            </pre> -->

            <ul class="color-variant" *ngIf="product.productVariations">
                <li [class]="color" *ngFor="let color of colorVariations(product.productVariations)"
                    [style]="setStyle(color)" [ngStyle]="{'background-color': color}"
                    (click)="ChangeVariants(color, product)">
                </li>
            </ul>



        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>