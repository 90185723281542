import { Data } from "@angular/router";

export interface IShipping {
    orderDate: Date;
    confirmDate: Date;
    expectedDate: Date;
    purchaseDate: Data;
    productName: string;
    link: string;
    webName?: string;
    contactBy?: string;
    type: string;
    color: string;
    size: string;
    weight: number;
    quantity: number;
    itemPrice: number;
    paidAmount: number;
    remainAmount: number;
    tax: number;
    shippingPrice: number;
    shippingStatus: ShippingStatus;
    localShippingPrice: number;
    note: string;
    box: number;
    buyerId: string;
    customerName: string;
    userId: number;
    userName: string;
    totalPrice: number;

    imageUrl: string;
    commission: number;
    shippingConfirm: ShippingConfirm;
    expressShipping: boolean;
    insurance: boolean;

    membership: Membership,
    internationalShippingAmount: number;


    couponPercentage: number;
    couponName: string;
    discount: number;


    extraFee: number;
    noteFroExtraFee?: string;

    height: number;
    width: number;
    length: number;





}

export interface IIndexShipping extends IShipping {
    shippingId: number;
}


export interface IShippingConfirm {
    shippingId: number;
    shippingConfirm: ShippingConfirm;
}


export enum ShippingStatus {
    Pending = 1,
    Reviewing = 2,
    Canceled = 3,
    Approved = 4,
    Purchased = 5,
    WarehouseDelivered = 6,
    IntShipping = 7,
    Transferring = 8,
    ArrivedToBranch = 9,
    Delivered = 10
}

export enum ShippingConfirm {
    Confirm = 1,
    Cancel = 2
}


export enum Membership {
    RegularMembership = 1,
    PrimeMembership = 2,
    BusinessMembership = 3,
}




