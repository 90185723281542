import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IIndexAccount } from '../Models/IAccount';
import { IIndexCoupon, IIndexOrder, IIndexOrderItem, IIndexProductRegistered, IOrder, IOrderItem, IQuantityForWholesale, IQuantityPriceForWholePrice } from '../Models/IOrder';
import { getPaginationHeaders, getPaginationResult } from '../Pagination/getPaginationResult';
import { IExtendPagination, IPagination, PaginatedResult } from '../Pagination/IPagination';

const state = {
    checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]')
}

@Injectable({
    providedIn: 'root'
})
export class OrderService {


    private baseUrl = `${environment.api}/orders`;
    private baseOrderItemItemUrl = `${environment.api}/OrderItems`;
    private baseCouponUrl = `${environment.api}/coupons`;

    private _orders$: BehaviorSubject<PaginatedResult<IIndexOrder[]> | null> = new BehaviorSubject(null);
    public _order$: BehaviorSubject<IIndexOrder | null> = new BehaviorSubject(null);

    private _orderItems$: BehaviorSubject<PaginatedResult<IIndexOrderItem[]> | null> = new BehaviorSubject(null);
    private _orderItem$: BehaviorSubject<IIndexOrderItem | null> = new BehaviorSubject(null);

    public _productsRegistered$: BehaviorSubject<PaginatedResult<IIndexProductRegistered[]> | null> = new BehaviorSubject(null);
    public _productRegisteredByBarcodeId$: BehaviorSubject<IIndexProductRegistered | null> = new BehaviorSubject(null);


    private _buyersListForOrder$: BehaviorSubject<PaginatedResult<IIndexAccount[]> | null> = new BehaviorSubject(null);
    public _coupon$: BehaviorSubject<IIndexCoupon | null> = new BehaviorSubject(null);

    get getOrders$(): Observable<PaginatedResult<IIndexOrder[]>> {
        return this._orders$.asObservable();
    }

    get getOrder$(): Observable<IIndexOrder> {
        return this._order$.asObservable();
    }

    get getOrderItems$(): Observable<PaginatedResult<IIndexOrderItem[]>> {
        return this._orderItems$.asObservable();
    }

    get getOrderItem$(): Observable<IIndexOrderItem> {
        return this._orderItem$.asObservable();
    }

    get getProductRegistersForAddProduct$(): Observable<PaginatedResult<IIndexProductRegistered[]>> {
        return this._productsRegistered$.asObservable();
    }

    get getProductRegistersForAddProductById$(): Observable<IIndexProductRegistered> {
        return this._productRegisteredByBarcodeId$.asObservable();
    }

    get getBuyersListForOrder$(): Observable<PaginatedResult<IIndexAccount[]>> {
        return this._buyersListForOrder$.asObservable();
    }

    get getCoupon$(): Observable<IIndexCoupon> {
        return this._coupon$.asObservable();
    }





    constructor(private router: Router,
        private _httpClient: HttpClient) { }

    // Get Checkout Items
    public get checkoutItems(): Observable<any> {
        const itemsStream = new Observable(observer => {
            observer.next(state.checkoutItems);
            observer.complete();
        });
        return <Observable<any>>itemsStream;
    }

    // Create order
    public createOrder(product: any, details: any, orderId: any, amount: any) {
        var item = {
            shippingDetails: details,
            product: product,
            orderId: orderId,
            totalAmount: amount
        };
        state.checkoutItems = item;
        localStorage.setItem("checkoutItems", JSON.stringify(item));
        localStorage.removeItem("cartItems");
        this.router.navigate(['/shop/checkout/success', orderId]);
    }



    ordersList(pramas?: IExtendPagination): Observable<PaginatedResult<IIndexOrder[]>> {
        const params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
        return this.loadOrders(params);
    }


    loadOrders(params: HttpParams): Observable<PaginatedResult<IIndexOrder[]>> {
        const result = getPaginationResult<IIndexOrder[]>(`${this.baseUrl}`, params, this._httpClient)
            .pipe(
                tap((orders) => {
                    this._orders$.next(orders);
                })
            );
        return result;
    }

    searchOrders(pramas?: IExtendPagination): Observable<PaginatedResult<IIndexOrder[]>> {
        let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

        if (pramas.searchTerm && pramas.searchTerm !== '') {
            params = params.append('searchTerm', pramas.searchTerm.toString());
        }


        if (pramas.fromDate && pramas.fromDate !== '') {
            params = params.append('fromDate', pramas.fromDate.toString());
            params = params.append('toDate', pramas.toDate.toString());
        }

        if (pramas.id && pramas.id.toString() !== '') {
            params = params.append('id', pramas.id.toString());
        }

        return this.loadOrders(params);
    }

    ordersListoFBuyer(buyerId: string, pramas?: IExtendPagination): Observable<PaginatedResult<IIndexOrder[]>> {
        const params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
        return this.loadOrdersListOfBuyer(buyerId, params);
    }

    loadOrdersListOfBuyer(buyerId: string, params: HttpParams): Observable<PaginatedResult<IIndexOrder[]>> {
        const result = getPaginationResult<IIndexOrder[]>(`${this.baseUrl}/OrderListOfBuyer`, params, this._httpClient)
            .pipe(
                tap((orders) => {
                    orders.result.forEach(order => {
                        order.orderItems.forEach(item => {
                            item.productImage = `${environment.imageApi}/Resources/Images/Product/${item.productImage}`;
                        })

                    })

                    this._orders$.next(orders);
                })
            );
        return result;
    }



    orderItemsList(barcode: string, pramas?: IExtendPagination): Observable<PaginatedResult<IIndexOrderItem[]>> {
        const params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
        return this.loadOrderItems(barcode, params);
    }

    loadOrderItems(barcode: string, params: HttpParams): Observable<PaginatedResult<IIndexOrderItem[]>> {
        const result = getPaginationResult<IIndexOrderItem[]>(`${this.baseOrderItemItemUrl}/list/${barcode}`, params, this._httpClient)
            .pipe(
                tap((items) => {
                    this._orderItems$.next(items);
                })
            );
        return result;
    }

    searchOrderItems(barcode: string, pramas?: IExtendPagination): Observable<PaginatedResult<IIndexOrderItem[]>> {
        let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
        if (pramas.searchTerm && pramas.searchTerm !== '') {
            params = params.append('searchTerm', pramas.searchTerm.toString());
        }
        return this.loadOrderItems(barcode, params);
    }



    getProductRegistered(productParams?: IPagination): Observable<PaginatedResult<IIndexProductRegistered[]>> {
        const params = getPaginationHeaders(productParams.currentPage, productParams.pageSize);
        return this.loadProductRegistered(params);
    }

    loadProductRegistered(params: HttpParams): Observable<PaginatedResult<IIndexProductRegistered[]>> {
        const result = getPaginationResult<IIndexProductRegistered[]>(`${this.baseUrl}/ProductRegisteredListAsync`, params, this._httpClient)
            .pipe(
                tap((products) => {
                    this._productsRegistered$.next(products);
                })
            );
        return result;
    }

    searchProductRegistered(productParams?: IExtendPagination): Observable<PaginatedResult<IIndexProductRegistered[]>> {
        let params = getPaginationHeaders(productParams.currentPage, productParams.pageSize);
        //    params = params.append('orderBy',positionParams.orderBy);
        if (productParams.searchTerm && productParams.searchTerm !== '') {
            params = params.append('searchTerm', productParams.searchTerm.toString());
        }

        return this.loadProductRegistered(params);
    }

    productRegisteredByBarcodeIdAsync(barcode: string): Observable<IIndexProductRegistered> {
        return this._httpClient.get<IIndexProductRegistered>(`${this.baseUrl}/ProductRegisteredByBarcodeIdAsync/${barcode}`)
    }


    getBuyersList(pramas?: IExtendPagination): Observable<PaginatedResult<IIndexAccount[]>> {
        const params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
        return this.loadBuyers(params);
    }

    loadBuyers(params: HttpParams): Observable<PaginatedResult<IIndexAccount[]>> {
        const result = getPaginationResult<IIndexAccount[]>(`${this.baseUrl}/BuyerAccountsListAsync`, params, this._httpClient)
            .pipe(tap((buyers) => {
                this._buyersListForOrder$.next(buyers);
            })
            );
        return result;
    }

    searchBuyers(pramas?: IExtendPagination): Observable<PaginatedResult<IIndexAccount[]>> {
        let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

        if (pramas.searchTerm && pramas.searchTerm !== '') {
            params = params.append('searchTerm', pramas.searchTerm.toString());
        }

        return this.loadBuyers(params);
    }



    orderWithItemsById(orderId: string): Observable<IIndexOrder> {

        return this._orders$.pipe(
            take(1),
            map((orders) => {

                const order = orders.result.find(value => value.orderId === orderId) || null;
                order.orderItems.forEach(item => {
                    item.productImage = `${environment.imageApi}/Resources/Images/Product/${item.productImage}`;
                })
                this._order$.next(order);
                return order;
            }),
            switchMap((order) => {
                if (!order) {
                    return throwError('Could not found the order with orderId of ' + orderId + '.');
                }
                return of(order);
            })
        );
    }




    orderWithItemsByIdInServer(orderId: string): Observable<IIndexOrder> {

        return this._httpClient.get<IIndexOrder>(`${this.baseUrl}/${orderId}`).pipe(
            map((order) => {
                order.orderItems.forEach(item => {
                    item.productImage = `${environment.imageApi}/Resources/Images/Product/${item.productImage}`;
                })
                this._order$.next(order);
                return order;
            })
        );
    }



    itemInOrderbyId(orderItemId: number): Observable<IIndexOrderItem> {
        return this._order$.pipe(
            take(1),
            map((order) => {
                const orderItem = order.orderItems.find(x => x.orderItemId == orderItemId);
                orderItem.productImage = `${environment.imageApi}/Resources/Images/Product/${orderItem.productImage}`;
                this._orderItem$.next(orderItem);
                return orderItem;
            })
        )
    }

    RetunItemAndUpdateQuantity(orderItemId: number, quantity: number): Observable<IIndexOrderItem> {
        return this._order$.pipe(
            take(1),
            map((order) => {
                let orderItem = order.orderItems.find(x => x.orderItemId == orderItemId);
                orderItem.productImage = `${environment.imageApi}/Resources/Images/Product/${orderItem.productImage}`;
                orderItem.quantity -= quantity;
                this._orderItem$.next(orderItem);
                return orderItem;
            })
        )
    }

    addOrder(data: IOrder): Observable<IIndexOrder> {
        return this._httpClient.post<IIndexOrder>(`${this.baseUrl}`, data);
    }

    addOrderItem(orderItem: IOrderItem): Observable<IIndexOrderItem> {
        return this._httpClient.post<IIndexOrderItem>(`${this.baseOrderItemItemUrl}/${orderItem.orderId}`, orderItem)
            .pipe(
                map((productItem) => {
                    this._order$.pipe(
                        map((product: IIndexOrder) => {
                            product.orderItems.forEach(item => {
                                item.productImage = `${environment.imageApi}/Resources/Images/Product/${item.productImage}`;
                            })
                            this._order$.next(product);
                        })
                    )
                    return productItem;
                })
            );
    }

    deleteOrderItem(orderItemId: number): Observable<IIndexOrder> {
        return this._httpClient.delete<IIndexOrder>(`${this.baseOrderItemItemUrl}/${orderItemId}`);
    }



    couponByName(couponName: string): Observable<IIndexCoupon> {
        return this._httpClient.get<IIndexCoupon>(`${this.baseCouponUrl}/coupon/${couponName}`);
    }


    CheckQuantityForWholeSale(quantityForWholesale: IQuantityForWholesale): Observable<IQuantityPriceForWholePrice> {
        return this._httpClient.get<IQuantityPriceForWholePrice>(`${this.baseUrl}/CheckQuantityForWholeSale/${quantityForWholesale.barcode}/${quantityForWholesale.quantity}`);
    }

    updateAllDiscountTypes(orderId: string): Observable<any> {
        return this._httpClient.put<any>(`${this.baseUrl}/UpdateAllDiscountTypes/${orderId}`, null);
    }





}
