import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Product } from '../../classes/product';
import { ICategoryCollection, ISubCategoryCollection } from '../../Models/ICategories';
import { IUser } from '../../Models/IUser';
import { IUsaProduct } from '../../Models/TopCollectionProduct';
import { IExtendPagination } from '../../Pagination/IPagination';
import { CategoryService } from '../../services/Category.service';
import { CustomerService } from '../../services/Customer.service';
import { HomeService } from '../../services/home.service';
import { IMenu, NavService } from '../../services/nav.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/usaBazaarLogo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  public menuItems: IMenu[];
  public categories: string[];


  public categoryCollection$: Observable<ICategoryCollection[]>;
  public categoryCollection: ICategoryCollection[] = [];
  
  
  public subCategoryCollection$: Observable<ISubCategoryCollection[]>;
  public subCategoryCollection: ISubCategoryCollection[] =[];
  
  public products: Product[] = [];
  public brandCollectionList: IUsaProduct[] = [];
  categoryName: string;
  subCategryName: string;
  brandName: string;




  pagination: IExtendPagination;

  constructor(
    public navServices: NavService,
    private _categoryService: CategoryService,
    private _router: Router,
    public _activatedRoute: ActivatedRoute,
    private viewScroller: ViewportScroller,
    private _homeService: HomeService


  ) { 
      
  }

  ngOnInit(): void {

    this.navServices.getMenus$.subscribe(menuItems => 
      {

        if(!menuItems)
        {
          this.navServices.loadMenus().subscribe();
        }
        if(menuItems)
        {
          this.categories = menuItems.map(m=>m.title)
        }

      }
      );

    this._router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

  }


  toggletNavActiveCategory(category: string) {


    
    this._categoryService.categoryQuery(category).subscribe();
    this._categoryService._subCategoryQuery$.next(null);
    this._categoryService._brandQuery$.next(null);

    

     
    var urlFull = this._router.url;
    let rul = urlFull.includes('/shop/product');


    if(rul == null  )
    {
      this._router.navigateByUrl(`/shop/product`)
    }
    else{
      this.pagination = {
        currentPage: 1,
        pageSize: 15,
        category:category,
        subCategory: null,
        brand: null
      };
      
      this._homeService.searchBrands(this.pagination).subscribe();    
    }

  }

  openLink(link: string)
  {
    window.open(link)

  }

  openWahtsup(value)
  {
    var result =`intent://send/${value}#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end"`
    return result;
  }


    // Click Toggle menu (Mobile)
    CategoryNavigation(category: string) {
  
      this.categoryName = category;
      this.subCategryName ='';
      this.brandName=''
  
      this._categoryService.categoryQuery(category).subscribe();
      this._homeService.loadSubCategoriesByCategoryName(this.categoryName).subscribe();
  
      this._categoryService._subCategoryQuery$.next(null);
      this._categoryService._brandQuery$.next(null);
  
      this.updateProductFilter();
    }


  updateProductFilter() {
    
    let params = { 
      category:this.categoryName?this.categoryName:null, 
      subCategory:this.subCategryName?this.subCategryName:null, 
      brand:this.brandName?this.brandName:null, 
    }

 

     this._router.navigate([], { 
       relativeTo: this._activatedRoute,
       queryParams: params,
       queryParamsHandling: 'merge', // preserve the existing query params in the route
       skipLocationChange: false  // do trigger navigation
     }).finally(() => {
       this.viewScroller.setOffset([120, 120]);
       this.viewScroller.scrollToAnchor('products'); // Anchore Link
     });


     
     this._router.navigateByUrl(`/shop/product`);      
  }

  homePage()
  {
   
    this._router.navigateByUrl('/home')
    .then(()=>{
      document.body.scrollTop = 0;
    });

  }


}
