<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content">
                                <img src="assets/images/usaBazaarLogo.png" style="height: 100px; margin-left: 40%;" alt="">
                                <h2>Register then get $5 as a reward</h2>
                                <form action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3" style="margin-top: 20px;">
                                        <!-- <input type="text" class="form-control" name="EMAIL" id="mce-EMAIL" placeholder="Enter your email" required="required"> -->
                                        <button (click)="modal.dismiss('Cross click')" [routerLink]="['/pages/register']" type="submit" class="btn btn-solid" id="mc-submit">Register</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->