<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2 *ngIf="!category; else thenCategory">{{ title |translate }}</h2>
                    <ng-template #thenCategory>

                        <h2>
                            <span style="cursor: pointer;"
                                (click)="onCategoryClick()">{{categoryTitle(category)}}</span> <span>&nbsp; >&nbsp;
                            </span>
                            <span style="cursor: pointer;"
                                (click)="onSubcategoryClick()">{{subCategoryTitle(subcategory)}}</span> <span>&nbsp; >
                                &nbsp; </span>
                            <span style="cursor: pointer;" (click)="onBrandClick()">{{brandCategoryTitle(brand)}}</span>
                            <span></span>

                        </h2>

                    </ng-template>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="'/home'">{{'Home'|translate}}</a></li>
                        <li class="breadcrumb-item active" style="color:rgb(218, 199, 199);" aria-current="page">{{
                            breadcrumb }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->