<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of saleProducts | slice:0:3 ">
            <ng-template carouselSlide>
                <div>
                    <div class="media" *ngFor="let product of saleProducts | slice:0:3 ">
                        <a [routerLink]="['/shop/product/left/sidebar',product.barcode]" href="#">
                            <!-- <img class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="product.images[0].src" alt=""> -->
                            <img #image [defaultImage]="'assets/images/product/giphy.gif'" [lazyLoad]="product.imageName" class="img-fluid lazy-loading cropped" [ngClass]="product.imageName" />

                        </a>
                        <div class="media-body align-self-center">
                            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                            <a [routerLink]="['/shop/product/left/sidebar/',product.barcode]">
                                <h6>{{ product.productName | titlecase }}</h6>
                            </a>
                            <h4>

                                {{ product?.unitPrice | discount:product | currency:currency?.currency:'symbol' }}
                                <!-- <h6 *ngIf="isWholeSaleAccount =='True'" style="margin-left: 20%; font-weight: bold; color: green;">wholesale price</h6> -->
                                <h6 *ngIf="isWholeSaleAccount =='True'" [ngStyle]="kurdishFormat()" style='font-weight: bold; color: green;'>{{''|discountText:product|translate}}</h6>
                                <del *ngIf="isWholeSaleAccount =='True' || product.newPrice>0"><span class="money" style=" font-weight: bold; color: brown;"> {{ product?.unitPrice | currency:currency?.currency:'symbol' }}</span></del>

                            </h4>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                    <div class="media" *ngFor="let product of saleProducts | slice:3:6 ">
                        <a [routerLink]="['/shop/product/left/sidebar',product.barcode]" href="#">
                            <!-- <img class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="product.images[0].src" alt=""> -->
                            <img #image [defaultImage]="'assets/images/product/giphy.gif'" [lazyLoad]="product.imageName" class="img-fluid lazy-loading cropped" [ngClass]="product.imageName" />
                        </a>
                        <div class="media-body align-self-center">
                            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                            <a [routerLink]="['/shop/product/left/sidebar/',product.barcode]">
                                <h6>{{ product.productName | titlecase }}</h6>
                            </a>
                            <h4>

                                {{ product?.unitPrice | discount:product | currency:currency?.currency:'symbol' }}
                                <h6 *ngIf="isWholeSaleAccount =='True'" style="margin-left: 20%; font-weight: bold; color: green;">wholesale price</h6>
                                <del *ngIf="isWholeSaleAccount =='True' || product.newPrice>0"><span class="money" style=" font-weight: bold; color: brown;"> {{ product?.unitPrice | currency:currency?.currency:'symbol' }}</span></del>

                            </h4>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->