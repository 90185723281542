import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { switchMap, catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthUtils } from '../guards/auth.utils';
import { IUser } from '../Models/IUser';



@Injectable({
    providedIn: 'root',
})

export class AuthService {
    private _authenticated: boolean = false;
    private baseAccountUrl = `${environment.api}/accounts`;
    public _currentUserSource$ = new ReplaySubject<IUser>(1);
    public _userRoles = new ReplaySubject<string[]>(null);


    get getCurrentUser$(): Observable<IUser> {
        return this._currentUserSource$.asObservable();
    }




    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _router: Router
    ) {
    }


    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }


    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }


    set userRoles(roles: string[]) {
        this._userRoles.next(roles);
    }

    get userRoles$(): Observable<string[]> {
        return this._userRoles.asObservable();
    }


    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    signIn(credentials: { email: string; password: string, rememberMe: boolean }): Observable<any> {

        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this.baseAccountUrl}/login`, credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;
                this.refreshToken = response.refreshToken;
                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                const roles = this.getDecodedToken(response.accessToken).role;
                const userId = this.getDecodedToken(response.accessToken).userId;
                const userName = this.getDecodedToken(response.accessToken).userName;
                const email = this.getDecodedToken(response.accessToken).email;
                const accountStatus = this.getDecodedToken(response.accessToken).status;
                const avatar = this.getDecodedToken(response.accessToken).avatar;
                const isWholeSaleAccount = this.getDecodedToken(response.accessToken).isWholeSaleAccount;
                const rewards = this.getDecodedToken(response.accessToken).rewards;

                const user: IUser = {
                    id: userId,
                    userName: userName,
                    email: email,
                    accountStatus: accountStatus,
                    avatar: avatar,
                    isWholeSaleAccount: isWholeSaleAccount,
                    rewards: rewards
                };

                this._userRoles.next(roles);

                this.setCurrentUser(user);

                // Return a new observable with the response
                return of(response);

            })
        );
    }

    getDecodedToken(accessToken: string) {
        const result: any = JSON.parse(atob(accessToken.split('.')[1]));
        return result;
    }

    setCurrentUser(currentUser: IUser): void {
        localStorage.setItem('user', JSON.stringify(currentUser));
        this._currentUserSource$.next(currentUser);
    }
    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {

        // Renew token
        // return this._httpClient.post('api/auth/refresh-access-token', {
        return this._httpClient.post(`${this.baseAccountUrl}/refresh-token`, { refreshToken: this.refreshToken }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {



                // Store the access token in the local storage
                this.accessToken = response.accessToken;
                this.refreshToken = response.refreshToken;
                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;


                // Store the user on the user service
                this._userService.user = response.user;
                const roles = this.getDecodedToken(response.accessToken).role;
                const userId = this.getDecodedToken(response.accessToken).userId;
                const userName = this.getDecodedToken(response.accessToken).userName;
                const email = this.getDecodedToken(response.accessToken).email;
                const accountStatus = this.getDecodedToken(response.accessToken).status;
                const isWholeSaleAccount = this.getDecodedToken(response.accessToken).isWholeSaleAccount;
                const rewards = this.getDecodedToken(response.accessToken).rewards;

                const avatar = this.getDecodedToken(response.accessToken).avatar;


                const currentUser: IUser = {
                    id: userId,
                    userName: userName,
                    email: email,
                    accountStatus: accountStatus,
                    avatar: avatar,
                    isWholeSaleAccount: isWholeSaleAccount,
                    rewards: rewards
                };

                this.setCurrentUser(currentUser);


                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('reward');



        // Set the authenticated flag to false
        this._authenticated = false;
        this._currentUserSource$.next(null);
        // Return the observable
        return forkJoin([
            of(true),
            // this._router.navigateByUrl('/')
        ]);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }



    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken || this.accessToken == 'undefined') {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }





    isAuthorized(allowedRole: string): boolean {

        // check if the list of allowed roles is empty, if empty,
        // authorize the user to access the page
        if (!allowedRole) {
            return false;
        }


        const roles: any = this.getDecodedToken(localStorage.getItem('accessToken')).role;


        if (typeof (roles) === 'string') {
            return allowedRole === roles;
        }


        var getRoles: string[] = roles;
        //   const result = allowedRoles.some(roleInAllowedRole => roles.includes(roleInAllowedRole));
        var result = getRoles.some(x => x === allowedRole);
        return result;




        var isTrue = false;
        for (let i = 0; i < roles.length; i++) {
            const element = roles[i];
            if (allowedRole === element) {
                isTrue = true;
                break;
            }
        }
        return isTrue;

    }


    // check many roles in login roles
    isRolesAuthorized(allowedRoles: string[]): boolean {

        // check if the list of allowed roles is empty, if empty,
        // authorize the user to access the page
        if (allowedRoles == null || allowedRoles.length === 0) {
            return true;
        }

        // get token from local storage or state management
        const token = sessionStorage.getItem('accessToken');



        // const decodeToken = this.jwtHelpService.decodeToken(token);  // it can be found in project 3 Complete Angular 11 - Ultimate Guide - with Real World App in
        // login.service and AdminRoutingModule
        // decode token to read the payload details
        const roles: any = this.getDecodedToken(localStorage.getItem('accessToken')).role;

        if (!roles) {
            return false;
        }

        if (typeof (roles) === 'string') {
            var authenticated = allowedRoles.some(x => x === roles)
            return authenticated;
        }


        // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
        const result = allowedRoles.some(roleInAllowedRole => roles.includes(roleInAllowedRole));
        // const result2 = roles.some(roleInDatabase => roles.allowedRoles(roleInDatabase));

        return result;
    }


}





@Injectable({
    providedIn: 'root',
})


export class UserService {
    private _user: ReplaySubject<IUser> = new ReplaySubject<IUser>(1);


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }



    set user(value: IUser) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<IUser> {
        return this._user.asObservable();
    }


    get(): Observable<IUser> {
        return this._httpClient.get<IUser>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    update(user: IUser): Observable<any> {
        return this._httpClient.patch<IUser>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }



}