import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { tap, take, map, switchMap, delay, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IIndexCustomer, IOrderToBuyer, ICustomerForUpdate, IDinarRate, ICustomerBalance } from '../Models/ICustomer';
import { getPaginationResult, getPaginationHeaders } from '../Pagination/getPaginationResult';
import { PaginatedResult, IExtendPagination } from '../Pagination/IPagination';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {


  private baseUrl = `${environment.api}/customerAccount`;
  private baseDinnarUrl = `${environment.api}/DinarRate`;
  private customerBalanceUrl = `${environment.api}/AccountBalance`;

  private _dinarRate$: BehaviorSubject<IDinarRate | null> = new BehaviorSubject(null);

  public _userInWholseAccount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _totalBalance$: BehaviorSubject<number> = new BehaviorSubject<number>(0);



  private _customerLists$: BehaviorSubject<PaginatedResult<IIndexCustomer[]> | null> = new BehaviorSubject(null);
  public _customer$: BehaviorSubject<IIndexCustomer | null> = new BehaviorSubject(null);

  private _ordersListsToBuyer$: BehaviorSubject<PaginatedResult<IOrderToBuyer[]> | null> = new BehaviorSubject(null);
  public _customerBalances$: BehaviorSubject<PaginatedResult<ICustomerBalance[]> | null> = new BehaviorSubject(null);


  get getCustomerList$(): Observable<PaginatedResult<IIndexCustomer[]>> {
    return this._customerLists$.asObservable();
  }

  get getOrdersListsToBuyer$(): Observable<PaginatedResult<IOrderToBuyer[]>> {
    return this._ordersListsToBuyer$.asObservable();
  }

  get getCustomer$(): Observable<IIndexCustomer> {
    return this._customer$.asObservable();
  }


  get getDinarRate$(): Observable<IDinarRate> {
    return this._dinarRate$.asObservable();
  }

  get getTotalBalance$(): Observable<number> {
    return this._totalBalance$.asObservable();
  }


  get getCustomerBalances$(): Observable<PaginatedResult<ICustomerBalance[]>> {
    return this._customerBalances$.asObservable();
  }


  constructor(private _httpClient: HttpClient) { }


  loadCustomer(params: HttpParams): Observable<PaginatedResult<IIndexCustomer[]>> {
    const result = getPaginationResult<IIndexCustomer[]>(`${this.baseUrl}`, params, this._httpClient)
      .pipe(
        tap((orders) => {
          this._customerLists$.next(orders);
        })
      );
    return result;
  }

  searchCustomer(pramas?: IExtendPagination): Observable<PaginatedResult<IIndexCustomer[]>> {
    let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

    if (pramas.searchTerm && pramas.searchTerm !== '') {
      params = params.append('searchTerm', pramas.searchTerm.toString());
    }

    if (pramas.fromDate && pramas.fromDate !== '') {
      params = params.append('fromDate', pramas.fromDate.toString());
      params = params.append('toDate', pramas.toDate.toString());
    }

    if (pramas.id && pramas.id.toString() !== '') {
      params = params.append('id', pramas.id.toString());
    }
    return this.loadCustomer(params);
  }

  loadOrderForBuyer(params: HttpParams): Observable<PaginatedResult<IOrderToBuyer[]>> {
    const result = getPaginationResult<IOrderToBuyer[]>(`${this.baseUrl}/BuyerOrdersList`, params, this._httpClient)
      .pipe(
        tap((ordersForCustomer) => {
          this._ordersListsToBuyer$.next(ordersForCustomer);
        })
      );
    return result;
  }

  searchOrdersForBuyer(pramas?: IExtendPagination): Observable<PaginatedResult<IOrderToBuyer[]>> {
    let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

    if (pramas.searchTerm && pramas.searchTerm !== '') {
      params = params.append('searchTerm', pramas.searchTerm.toString());
    }

    if (pramas.fromDate && pramas.fromDate !== '') {
      params = params.append('fromDate', pramas.fromDate.toString());
      params = params.append('toDate', pramas.toDate.toString());
    }

    if (pramas.guidId && pramas.guidId.toString() !== '') {
      params = params.append('guidId', pramas.guidId.toString());
    }
    return this.loadOrderForBuyer(params);
  }




  customeById(customerId: string): Observable<IIndexCustomer> {
    return this._customerLists$.pipe(
      take(1),
      map((customers) => {
        const customer = customers.result.find(value => value.id === customerId) || null;
        this._customer$.next(customer);
        return customer;
      }),
      switchMap((customer) => {
        if (!customer) {
          return throwError(() => new Error('Could not found the order with orderId of ' + customerId + '.'));
        }
        return of(customer);
      })
    );
  }


  customerByIdInServer(customerId: string): Observable<IIndexCustomer> {

    this.getTotalBalance(customerId);
    return this._httpClient.get<IIndexCustomer>(`${this.baseUrl}/${customerId}`).pipe(
      map((customer) => {
        this._customer$.next(customer);
        return customer;
      })
    );

  }


  addCustomer(customer: FormData): Observable<IIndexCustomer> {
    return this._httpClient.post<IIndexCustomer>(`${this.baseUrl}`, customer)
      .pipe(
        map((customer) => {

          this._customer$.next(customer);
          return customer;
        }),
        switchMap((customer) => {
          if (!customer) {
            return throwError(() => new Error('Could not add the customer' + '.'));
          }
          return of(customer);
        })
      );
  }




  updateCustomer(customer: ICustomerForUpdate): Observable<IIndexCustomer> {
    return this._httpClient.put<IIndexCustomer>(`${this.baseUrl}/${customer.id}`, customer)
      .pipe(
        map((customer) => {
          this._customer$.next(customer);
          return customer;
        }),
        switchMap((customer) => {
          if (!customer) {
            return throwError(() => new Error('Could not add the customer' + '.'));
          }
          return of(customer);
        })
      );
  }



  deletetCustomer(customerId: string): Observable<IIndexCustomer> {
    return this._customerLists$.pipe(
      take(1),
      switchMap((customers: PaginatedResult<IIndexCustomer[]>) =>
        this._httpClient.delete<IIndexCustomer>(`${this.baseUrl}/${customerId}`,)
          .pipe(
            map((newCustomer: IIndexCustomer) => {

              // Update the positions with the new position

              const customerLists: IIndexCustomer[] = customers.result;
              customers.result.unshift(newCustomer);
              if (customerLists.length > customers.pagination.pageSize) {
                customers.result.pop();
              }
              this._customerLists$.next(customers);
              // Return the new contact
              return newCustomer;
            })
          ))
    );
  }


  checkExistEmail(email: string): Observable<boolean> {
    return of(email).pipe(
      delay(1000),
      switchMap(() => this._httpClient.get<boolean>(`${this.baseUrl}/CheckExistEmail/${email}`))
    );
  }

  checkExistPhoneNumber(phoneNumber: string): Observable<boolean> {
    return of(phoneNumber).pipe(
      delay(1000),
      switchMap(() => this._httpClient.get<boolean>(`${this.baseUrl}/checkExistPhoneNumber/${phoneNumber}`))
    );
  }

  ConfirmEmail(userId: string, token: string): Observable<any> {

    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('userId', userId);
    params = params.append('token', token);
    return this._httpClient.get<any>(`${this.baseUrl}/Confirmation`, { params: params })
  }


  sendPasswordToClient(email: string): Observable<any> {
    return this._httpClient.post<any>(`${this.baseUrl}/sendPasswordToMail/${email}`, {});
  }


  dinarRate(): Observable<IDinarRate> {
    return this._httpClient.get<IDinarRate>(this.baseDinnarUrl)
      .pipe(
        map((rate: IDinarRate) => {
          this._dinarRate$.next(rate);
          return rate;
        })
      );
  }


  changePassword(password: string): Observable<any> {

    return this._httpClient.post(`${this.baseUrl}/changePassword`, { password: password });
  }


  changeAddress(address: any): Observable<any> {

    return this._httpClient.post(`${this.baseUrl}/changeAddress`, { address: address.address, cityName: address.cityName });
  }


  changePhoneNumber(phone: any): Observable<any> {
    return this._httpClient.post(`${this.baseUrl}/UpdatePhoneNumber`, { phoneNumber: phone.phoneNumber, phoneNumber2: phone.phoneNumber2 });
  }



  updateCustomerImage(customerImage: FormData): Observable<IIndexCustomer> {
    return this._httpClient.put<IIndexCustomer>(`${this.baseUrl}/UpdateCustomerImage`, customerImage);
  }

  changeFullName(fullName: any): Observable<any> {
    return this._httpClient.post(`${this.baseUrl}/ChangeFullName`, { fullName: fullName });
  }



  getTotalBalance(customerId: string): Observable<number> {


    return this._httpClient.get<number>(`${this.customerBalanceUrl}/CustomerTotal/${customerId}`)
      .pipe(
        map((total: number) => {

          this._totalBalance$.next(total);
          return total;
        })
      );
  }


  loadCustomerBalance(params: HttpParams): Observable<PaginatedResult<ICustomerBalance[]>> {
    const result = getPaginationResult<ICustomerBalance[]>(`${this.customerBalanceUrl}`, params, this._httpClient)
      .pipe(
        tap((cb) => {
          for (let i = 0; i < cb.result.length; i++) {
            if (cb.result[i].typeAmount === 2) {
              cb.result[i].amount *= -1;
            } else {
              cb.result[i].amount *= 1;
            }
          }
          this._customerBalances$.next(cb);
        }),
        retry(3)
      );
    return result;
  }






  searchCustomerBalances(pramas?: IExtendPagination): Observable<PaginatedResult<ICustomerBalance[]>> {
    let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

    if (pramas.searchTerm && pramas.searchTerm !== '') {
      params = params.append('searchTerm', pramas.searchTerm.toString());
    }

    if (pramas.orderBy && pramas.orderBy !== '') {
      params = params.append('orderBy', pramas.orderBy.toString());
    }



    if (pramas.fromDate && pramas.fromDate !== '') {
      params = params.append('fromDate', pramas.fromDate.toString());
      params = params.append('toDate', pramas.toDate.toString());
    }



    if (pramas.customerId && pramas.customerId.toString() !== '') {
      params = params.append('customerId', pramas.customerId.toString());
    }


    return this.loadCustomerBalance(params);
  }








}


