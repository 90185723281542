<!--footer section -->
<footer [class]="class">

    <!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->

    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4 style="color: white;">about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img [src]="themeLogo" alt="logo" (click)="homePage()"
                                style="cursor: pointer; width: 150px;">
                        </div>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p> -->
                        <div class="footer-social" style="color: rgb(218, 199, 199);">
                            <ul>

                                <li>
                                    <a (click)="openLink('https://www.facebook.com/usaonlinebazaar')"
                                        style="cursor: pointer;"><i style="color: rgb(218, 199, 199);"
                                            class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>


                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li> -->
                                <li>
                                    <a (click)="openLink('https://www.instagram.com/usa_bazaar/')"
                                        style="cursor: pointer;"><i style="color: rgb(218, 199, 199);"
                                            class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a (click)="openLink('https://www.instagram.com/usa_bazaar_beauty/')"
                                        style="cursor: pointer;"><i style="color: rgb(218, 199, 199);"
                                            style="color: white;" class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a (click)="openLink('https://www.youtube.com/channel/UCtJdrqY89ZlXqwTB44Z97fQ/')"
                                        style="cursor: pointer;"><i style="color: rgb(218, 199, 199);"
                                            class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>

                                <li>
                                    <a (click)="openLink('https://vm.tiktok.com/TTPdArTjjy/')" style="cursor: pointer;">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
                                            width="0.88em" height="1em" preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 448 512">
                                            <path fill="currentColor"
                                                d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121.18 121.18 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="openLink('https://www.snapchat.com/add/usa_bazaar?share_id=QzkwNTFF&locale=en_US/')"
                                        style="cursor: pointer;"><i style="color: rgb(218, 199, 199);"
                                            class="fa fa-snapchat" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 style="color:rgb(218, 199, 199);">{{'Categories'|translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngFor="let category of categories"><a
                                        style="cursor: pointer; color:rgb(218, 199, 199);"
                                        (click)="CategoryNavigation(category)">{{category}}</a></li>
                                <!-- <li><a href="javascript:void(0)">Womens Fashion</a></li>
                                <li><a href="javascript:void(0)">Mens Fashion</a></li>
                                <li><a href="javascript:void(0)">Kids Fashion</a></li>
                                <li><a href="javascript:void(0)">Featured</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">shipping & return</a></li>
                                <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->


                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 style="color:rgb(218, 199, 199);">{{'store information'|translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li style="cursor: pointer; color:rgb(218, 199, 199);"
                                    (click)="openLink('https://www.google.com/maps/place/USA+Bazaar/@36.2001816,44.0278669,16z/data=!4m12!1m6!3m5!1s0x400723c0ab062ecf:0xbd48b35d721bd70e!2sUSA+Bazaar!8m2!3d36.200487!4d44.0302877!3m4!1s0x400723c0ab062ecf:0xbd48b35d721bd70e!8m2!3d36.200487!4d44.0302877?hl=en')">
                                    <i style="color:rgb(218, 199, 199);" class="fa fa-map-marker"></i> {{'Erbil, Brayati
                                    Street. Near Gift
                                    Center'|translate}}
                                </li>
                                <li>
                                    <i style="color:rgb(218, 199, 199);" class="fa fa-phone"></i>
                                    <a style="cursor: pointer; color:rgb(218, 199, 199);"
                                        href="https://wa.me/009647508166007"
                                        target="_blank">{{'Phone:0750-816-6007'|translate}}</a>

                                </li>
                                <li><i style="color:rgb(218, 199, 199);" class="fa fa-phone"></i>
                                    <a style="cursor: pointer; color:rgb(218, 199, 199);" href="tel:009647508166008">
                                        {{'Viber: 0750 - 816 - 6008'|translate}}</a>
                                </li>
                                <li><i style="color:rgb(218, 199, 199);" class="fa fa-phone"></i>
                                    <a style="cursor: pointer; color:rgb(218, 199, 199);" href="tel:009647508166009">
                                        {{'WhatsApp: 0750 - 816 - 6009'|translate}}</a>
                                </li>

                                <li style="cursor: pointer; color:rgb(218, 199, 199);"><i class="fa fa-envelope-o"></i>
                                    {{'Email Us:'|translate}}
                                    <a style="cursor: pointer; color:rgb(218, 199, 199);"
                                        href="mailto:info@usabazaar.net">info@usabazaar.net</a>
                                </li>




                                <li><i style="color:rgb(218, 199, 199);" class="fa fa-connectdevelop"></i>
                                    <a style="cursor: pointer; color:rgb(218, 199, 199);"
                                        [routerLink]="['../pages/career']"> {{'Apply for a job'|translate}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} </p>
                    </div>
                </div>

                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</footer>
<!--footer section end -->