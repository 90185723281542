import { Component, OnInit, Input } from "@angular/core";
import { NewProductSlider } from "../../../data/slider";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { IUsaProduct } from "src/app/shared/Models/TopCollectionProduct";
import { IUser } from "src/app/shared/Models/IUser";

@Component({
  selector: "app-product-box-vertical-slider",
  templateUrl: "./product-box-vertical-slider.component.html",
  styleUrls: ["./product-box-vertical-slider.component.scss"],
})
export class ProductBoxVerticalSliderComponent implements OnInit {
  @Input() title: string = "New Product"; // Default
  @Input() type: string = "fashion"; // Default Fashion
  currency: any = this.productService.Currency; // Default Currency
  public products: Product[] = [];
  @Input() saleProducts: IUsaProduct[]; // Default Fashion
  isWholeSaleAccount = "False";
  user: IUser;
  isLanguageInKurdish = false;

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService) {
    var languageInKurdish = localStorage.getItem("code");
    if (languageInKurdish) {
      this.isLanguageInKurdish = true;
    }
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user) {
      this.isWholeSaleAccount = this.user.isWholeSaleAccount.toString();
    }

    // this.productService.getProducts.subscribe(response =>
    //   this.products = response.filter(item => item.type == this.type)
    // );
  }

  ngOnInit(): void {}


  kurdishFormat(): Object {

    
    if (this.isLanguageInKurdish) {
      return {
        "margin-right": "40%",
      };
    }
    return {
      "margin-left": "40%",
    };
  }
}
