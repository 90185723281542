import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of, zip } from "rxjs";
import { groupBy, map, mergeMap, scan, switchMap, take, tap, toArray } from "rxjs/operators";
import {
  ISliderImage,
  IIndexTopCollectionSlide,
} from "../Models/IIndexSliderImage";
import { IExtendPagination, PaginatedResult } from "../Pagination/IPagination";
import {
  getPaginationHeaders,
  getPaginationResult,
} from "../Pagination/getPaginationResult";
import {
  ISubBrandCollection,
  ICategoryCollection,
  ISubCategoryCollection,
} from "../Models/ICategories";
import { DefaultGlobalConfig, ToastrService } from "ngx-toastr";
import {
  IUsaProduct,
  IBaseItem,
  IAddBaseItem,
  ICartItem,
  IDeleteBaseItem,
} from "../Models/TopCollectionProduct";
import { Router } from "@angular/router";
import { IUser } from "../Models/IUser";

// Menu

@Injectable({
  providedIn: "root",
})
export class HomeService {
  private baseUrl = `${environment.api}/SliderImages`;
  private baseHomeUrl = `${environment.api}/Home`;
  private baseItemUrl = `${environment.api}/BaseItems`;

  private _sliders$: BehaviorSubject<ISliderImage[] | null> = new BehaviorSubject(null);
  private _topCollectionsSlide$: BehaviorSubject<IIndexTopCollectionSlide[] | null> = new BehaviorSubject(null);
  private _topCollections$: BehaviorSubject<IUsaProduct[] | null> = new BehaviorSubject(null);

  private _brandCollectionsList$: BehaviorSubject<PaginatedResult<IUsaProduct[]> | null> = new BehaviorSubject(null);
  public _brandCollection$: BehaviorSubject<IUsaProduct | null> = new BehaviorSubject(null);

  private _categoryCollection$: BehaviorSubject<ICategoryCollection[] | null> = new BehaviorSubject(null);
  private _subCategoryCollection$: BehaviorSubject<ISubCategoryCollection[] | null> = new BehaviorSubject(null);
  private _subBrandCollection$: BehaviorSubject<ISubBrandCollection[] | null> = new BehaviorSubject(null);



  private _saleProducts$: BehaviorSubject<IUsaProduct[] | null> = new BehaviorSubject(null);


  private _topCollectionById$: BehaviorSubject<IUsaProduct | null> = new BehaviorSubject(null);

  public _cartItems$: BehaviorSubject<IBaseItem[] | null> = new BehaviorSubject(null);
  private _cartItem$: BehaviorSubject<IBaseItem | null> = new BehaviorSubject(null);

  private _wishListItems$: BehaviorSubject<IBaseItem[] | null> = new BehaviorSubject(null);
  private _wishListItem$: BehaviorSubject<IBaseItem | null> = new BehaviorSubject(null);

  private _compareItems$: BehaviorSubject<IBaseItem[] | null> = new BehaviorSubject(null);
  private _compareItem$: BehaviorSubject<IBaseItem | null> = new BehaviorSubject(null);

  get getSlider$(): Observable<ISliderImage[]> {
    return this._sliders$.asObservable();
  }

  get getTopCollectionsSlide$(): Observable<IIndexTopCollectionSlide[]> {
    return this._topCollectionsSlide$.asObservable();
  }

  get getSaleProducts$(): Observable<IUsaProduct[]> {
    return this._saleProducts$.asObservable();
  }





  get getTopCollections$(): Observable<IUsaProduct[]> {
    return this._topCollections$.asObservable();
  }

  get getBrandCollectionList$(): Observable<PaginatedResult<IUsaProduct[]>> {
    return this._brandCollectionsList$.asObservable();
  }

  get getBrand$(): Observable<IUsaProduct> {
    return this._brandCollection$.asObservable();
  }

  get getCategoryCollection$(): Observable<ICategoryCollection[]> {
    return this._categoryCollection$.asObservable();
  }

  get getSubCategoryCollection$(): Observable<ISubCategoryCollection[]> {
    return this._subCategoryCollection$.asObservable();
  }

  get getSubBrandCollection$(): Observable<ISubBrandCollection[]> {
    return this._subBrandCollection$.asObservable();
  }

  get getTopCollectionById$(): Observable<IUsaProduct> {
    return this._topCollectionById$.asObservable();
  }



  get getCartItems$(): Observable<IBaseItem[]> {
    return this._cartItems$.asObservable();
  }
  get getCartItem$(): Observable<IBaseItem> {
    return this._cartItem$.asObservable();
  }

  get getWishListItems$(): Observable<IBaseItem[]> {
    return this._wishListItems$.asObservable();
  }
  get getWishListItem$(): Observable<IBaseItem> {
    return this._wishListItem$.asObservable();
  }

  get getCompareItems$(): Observable<IBaseItem[]> {
    return this._compareItems$.asObservable();
  }
  get getCompareItem$(): Observable<IBaseItem> {
    return this._compareItem$.asObservable();
  }

  constructor(private _httpClient: HttpClient,
    private _router: Router,
    private _toastrService: ToastrService) { }

  public screenWidth: any;
  public leftMenuToggle: boolean = false;
  public mainMenuToggle: boolean = false;

  loadSliderImages(): Observable<ISliderImage[]> {
    const result = this._httpClient
      .get<ISliderImage[]>(`${this.baseUrl}/Last3SliderImageListAsync`)
      .pipe(
        tap((sliderImages) => {
          sliderImages.map((value) => {
            value.imageName = `${environment.imageApi}/Resources/Images/slider/${value.imageName}`;
          });
          this._sliders$.next(sliderImages);
        })
      );
    return result;
  }

  loadTopCollectionsSlide(): Observable<IIndexTopCollectionSlide[]> {
    const result = this._httpClient
      .get<IIndexTopCollectionSlide[]>(`${this.baseUrl}/TopCollectionsSlide`)
      .pipe(
        tap((topCollections) => {
          topCollections.map((value) => {
            value.imageName = `${environment.imageApi}/Resources/Images/slider/${value.imageName}`;
          });

          this._topCollectionsSlide$.next(topCollections);
        })
      );
    return result;
  }



  loadTopCollections(buyerId?: string): Observable<IUsaProduct[]> {
    var url = !buyerId ? `${this.baseHomeUrl}/Top16Productions` : `${this.baseHomeUrl}/Top16Productions/${buyerId}`;
    const result = this._httpClient
      .get<IUsaProduct[]>(url)
      .pipe(
        tap((topCollections) => {
          topCollections.map((item) => {
            item.imageName = `${environment.imageApi}/Resources/Images/Product/${item.imageName}`;
          });
          this._topCollections$.next(topCollections);
        })
      );
    return result;
  }



  // brandsList(slug: string,pramas?: IExtendPagination): Observable<PaginatedResult<IUsaProduct[]>> {
  brandsList(pramas?: IExtendPagination): Observable<PaginatedResult<IUsaProduct[]>> {
    const params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
    return this.loadBrands(params);
  }

  // private loadBrands(slug: string,params: HttpParams): Observable<PaginatedResult<IUsaProduct[]>> {
  private loadBrands(params: HttpParams): Observable<PaginatedResult<IUsaProduct[]>> {

    const result = getPaginationResult<IUsaProduct[]>(
      `${this.baseHomeUrl}/brandNameCollection`, params, this._httpClient)
      .pipe(
        tap((brands) => {
          brands.result.map((item) => {
            item.imageName = `${environment.imageApi}/Resources/Images/Product/${item.imageName}`;
          });

          this._brandCollectionsList$.next(brands);
        })
      );
    return result;
  }

  // searchBrands(slug: string,    pramas?: IExtendPagination): Observable<PaginatedResult<IUsaProduct[]>> {
  searchBrands(pramas?: IExtendPagination): Observable<PaginatedResult<IUsaProduct[]>> {

    let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

    if (pramas.searchTerm && pramas.searchTerm !== "") {
      params = params.append("searchTerm", pramas.searchTerm.toString());
    }

    if (pramas.fromDate && pramas.fromDate !== "") {
      params = params.append("fromDate", pramas.fromDate.toString());
      params = params.append("toDate", pramas.toDate.toString());
    }

    if (pramas.id && pramas.id.toString() !== "") {
      params = params.append("id", pramas.id.toString());
    }

    if (pramas.category && pramas.category !== "") {
      params = params.append("category", pramas.category.toString());
    }

    if (pramas.subCategory && pramas.subCategory !== "") {
      params = params.append("subCategory", pramas.subCategory.toString());
    }

    if (pramas.brand && pramas.brand !== "") {
      params = params.append("brand", pramas.brand.toString());
    }

    if (pramas.maxPrice) {
      params = params.append("maxPrice", pramas.maxPrice.toString());
    }

    if (pramas.minPrice) {
      params = params.append("minPrice", pramas.minPrice.toString());
    }

    if (pramas.orderBy) {
      params = params.append("orderBy", pramas.orderBy.toString());
    }

    if (pramas.filtered) {
      params = params.append("filtered", pramas.filtered.toString());
    }

    if (pramas.buyerId) {
      params = params.append("buyerId", pramas.buyerId.toString());
    }

    if (pramas.wholesalerAccount) {
      params = params.append("wholesalerAccount", pramas.wholesalerAccount.toString());
    }


    return this.loadBrands(params);
  }


  loadCategoriesCollection(): Observable<ICategoryCollection[]> {
    const result = this._httpClient
      .get<ICategoryCollection[]>(`${this.baseHomeUrl}/CategoryCollection`)
      .pipe(
        tap((categories) => {
          if (categories) {
            categories.forEach(c => {
              c.mainCategoryName = c.categoryName;
              var code = localStorage.getItem('code');
              if (code) {
                c.categoryName = c.categoryNameInKurdish ? c.categoryNameInKurdish : c.mainCategoryName;
              } else {
                c.categoryName = c.mainCategoryName;
              }
            })
          }
          this._categoryCollection$.next(categories);
        })
      );
    return result;
  }

  loadSubCategoriesCollection(categoryId: number, slug: string = ""): Observable<ISubCategoryCollection[]> {
    const result = this._httpClient.get<ISubCategoryCollection[]>(`${this.baseHomeUrl}/SubCategoriesCollection/${categoryId}/${slug}`)
      .pipe(
        tap((subCategories) => {


          if (subCategories) {
            subCategories.forEach(c => {
              var code = localStorage.getItem('code');
              if (code) {
                c.subCategoryName = c.subCategoryNameInKurdish ? c.subCategoryNameInKurdish : c.mainSubCategoryName;
              } else {
                c.subCategoryName = c.mainSubCategoryName
              }
            })
          }




          this._subCategoryCollection$.next(subCategories);
        })
      );
    return result;
  }

  loadSubCategoriesByCategoryName(categoryName: string): Observable<ISubCategoryCollection[]> {
    const result = this._httpClient.get<ISubCategoryCollection[]>(`${this.baseHomeUrl}/SubCategoriesByName/${categoryName}`)
      .pipe(
        tap((subCategories) => {
          this._subCategoryCollection$.next(subCategories);
        })
      );
    return result;
  }



  loadSubBrandCollectionCollection(subCategoryId: number): Observable<ISubBrandCollection[]> {
    const result = this._httpClient
      .get<ISubBrandCollection[]>(
        `${this.baseHomeUrl}/BrandsCollection/${subCategoryId}`
      )
      .pipe(
        tap((subBrandCollection) => {


          if (subBrandCollection) {
            subBrandCollection.forEach(c => {
              var code = localStorage.getItem('code');
              if (code) {
                c.subManagementName = c.subManagementNameInKurdish ? c.subManagementNameInKurdish : c.mainSubManagementName;
              } else {
                c.subManagementName = c.mainSubManagementName;
              }
            })
          }


          this._subBrandCollection$.next(subBrandCollection);
        })
      );
    return result;
  }


  loadsubBrandBySubCategoryName(subCategoryName: string): Observable<ISubBrandCollection[]> {
    const result = this._httpClient
      .get<ISubBrandCollection[]>(
        `${this.baseHomeUrl}/BrandsByName/${subCategoryName}`
      )
      .pipe(
        tap((subBrandCollection) => {
          this._subBrandCollection$.next(subBrandCollection);
        })
      );
    return result;
  }


  resetSubBrandCollection() {
    this._subBrandCollection$.next(null);
  }


  topCollectionById(barcode: string): Observable<IUsaProduct> {

    let buyerId = '';
    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user) {
      buyerId = user.id;
    }

    let params = new HttpParams().set('buyerId', buyerId);


    return this._httpClient
      .get<IUsaProduct>(`${this.baseHomeUrl}/BrandCollectionById/${barcode}`, { params: params })
      .pipe(
        map((product) => {
          product.imageName = `${environment.imageApi}/Resources/Images/Product/${product.imageName}`;
          let images = product.images.map((image) => {
            image = `${environment.imageApi}/Resources/Images/Product/${image}`;
            return image;
          });



          let tags = product.tags.map((tag) => {
            tag.imageName = `${environment.imageApi}/Resources/Images/Product/${tag.imageName}`;
            return tag;
          });



          let isYoutubeNull = product.youtube;

          if (isYoutubeNull != "null" && isYoutubeNull != null) {
            let youtube = `https://www.youtube.com/embed/${product.youtube}`;
            product.youtube = youtube;
          }

          product.tags = tags;
          product.images = images;
          this._topCollectionById$.next(product);
          return product;
        })
      );
  }

  // baseItem
  baseItems(tableName: string): Observable<IBaseItem[]> {
    return this._httpClient
      .get<IBaseItem[]>(`${this.baseItemUrl}/${tableName}`)
      .pipe(
        map((baseItems) => {


          if (tableName == "CartItem") {
            this._cartItems$.next(null);

            var cartItems: ICartItem[] = [];

            baseItems.forEach((item) => {
              item.imageName = `${environment.imageApi}/Resources/Images/Product/${item.imageName}`;
            });


            cartItems = baseItems;

            // for (let i = 0; i < baseItems.length; i++) {
            //   const element = baseItems[i];
            //   cartItems.push(element);

            //   // let isBarcodeRepeated = cartItems.some((x) => x.barcode == element.barcode);
            //   // if (isBarcodeRepeated) {
            //   //   cartItems.forEach((item) => {

            //   //     if (item.barcode == element.barcode) {
            //   //       item.quantitiesInCartItem += element.quantitiesInCartItem;
            //   //     }
            //   //   });
            //   // } else {

            //   //   cartItems.push(element);
            //   // }
            // }


            this._cartItems$.next(cartItems);
            return baseItems

          }

          else if (tableName == "WishListItem") {
            this._wishListItems$.next(null);
            baseItems.forEach((item) => {
              item.imageName = `${environment.imageApi}/Resources/Images/Product/${item.imageName}`;
            });
            this._wishListItems$.next(baseItems);
            return baseItems
          }
        })
      );



  }

  baseItemById(baseItemId: number): Observable<IBaseItem> {
    return this._httpClient.get<IBaseItem>(`${this.baseUrl}/${baseItemId}`);
  }

  addBaseItem(baseItem: IAddBaseItem): Observable<IBaseItem> {
    return this._httpClient
      .post<IBaseItem>(`${this.baseItemUrl}`, baseItem)
      .pipe(
        map((response: IBaseItem) => {
          let tableName = response.tableName;

          if (tableName == "CartItem") {
            response.imageName = `${environment.imageApi}/Resources/Images/Product/${response.imageName}`;
            this._cartItems$
              .pipe(
                map((cartItems: IBaseItem[]) => {

                  if (!cartItems) {
                    return;
                  }
                  let isAddedToCart = cartItems.some((x) => x.barcode == response.barcode);
                  if (isAddedToCart) {
                    cartItems.forEach((item) => {

                      if (item.barcode == response.barcode) {
                        //  item.quantitiesInCartItem += response.quantitiesInCartItem;
                        item.quantitiesInCartItem += baseItem.quantitiesInCartItem;
                      }
                    });
                  } else {
                    cartItems.push(response);
                  }

                  return cartItems;
                })
              )
              .subscribe();

            return response;
          }
          else if (tableName == "CompareItem") {
            let myCompareItem: IBaseItem[] = [];
            myCompareItem.push(response);
            this._compareItems$.next(myCompareItem);
            return;
          }
          return response;
        })
      );
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this._cartItems$.pipe(
      map((product: IBaseItem[]) => {
        if (!product) {
          return;
        }
        return product.reduce((prev, curr: IBaseItem) => {
          let price = curr.unitPrice;
          return prev + price * curr.quantitiesInCartItem;
        }, 0);
      })
    );
  }


  // Update Cart Quantity
  public updateCartQuantity(product: IBaseItem, quantity: number) {
    return this._cartItems$.pipe(
      map((products: IBaseItem[]) => {

        products.find((carts, index) => {


          if (carts.barcode == product.barcode) {
            const qty = carts[index].quantity + quantity
            const stock = this.calculateStockCounts(carts[index], quantity)
            if (qty !== 0 && stock) {
              carts[index].quantity = qty
            }
          }

        })

      })
    );
  }


  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {

    let quantitiesInCartItem = 0;
    this.getCartItems$.subscribe((cartItems: IBaseItem[]) => {
      if (cartItems) {
        var cartItem = cartItems.find(x => x.barcode == product.barcode);

        if (cartItem) {
          quantitiesInCartItem = cartItem.quantitiesInCartItem;
        }

      }
    })

    const qty = product.quantitiesInCartItem == 0 && quantitiesInCartItem != 0 ? quantitiesInCartItem + quantity : product.quantitiesInCartItem + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this._toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }

    if (qty <= 0) {
      this._toastrService.error('No more item quantity removing in cart item.');
      return false
    }
    return true
  }


  // Remove Cart items by Barcode
  public removeCartItem(product: IDeleteBaseItem): Observable<any> {


    return this._httpClient.delete<any>(`${this.baseItemUrl}/deleteByBarcode/${product.tableName}/${product.barcode}`).pipe(
      map(() => {
        this.getCartItems$.subscribe(cartItems => {

          if (cartItems == null || !cartItems) {
            return;
          }

          const index = cartItems.findIndex(x => x.barcode == product.barcode);
          if (index == -1) {
            return;
          }
          cartItems.splice(index, 1);
          return true;
        })
      })
    );



  }


  deleteBaseItemByBarcode(baseItem: IDeleteBaseItem): Observable<any> {

    return this._httpClient.delete<any>(`${this.baseItemUrl}/deleteByBarcode/${baseItem.tableName}/${baseItem.barcode}`).pipe(
      tap(t => {
        this.baseItems('CartItem').subscribe((data) => console.log(data))
      })
    );
  }

  deleteBaseItemByBaseItemId(baseItemId: number): Observable<any> {

    return this._httpClient.delete<any>(`${this.baseItemUrl}/${baseItemId}`).pipe(
      tap(t => {
        this.baseItems('WishListItem').subscribe()
      })
    );
  }



  // Remove Cart items by Barcode
  public deleteCartItemsByBuyer(tableName: string): Observable<any> {


    return this._httpClient.delete<any>(`${this.baseItemUrl}/deleteBaseItemsByBuyer/${tableName}`).pipe(
      map(() => {
        this.getCartItems$.subscribe(cartItems => {

          if (cartItems == null || !cartItems) {
            return;
          }
          let dd = cartItems[0];

          for (var i = cartItems.length - 1; i >= 0; i--) {
            cartItems.splice(i, 1);
          }

          return true;
        })
      })
    );;

  }




  salesProductList(): Observable<IUsaProduct[]> {
    const result = this._httpClient.get<IUsaProduct[]>(`${this.baseHomeUrl}/SaleProductList`)
      .pipe(
        tap((saleProducts) => {
          saleProducts.map((item) => {
            item.imageName = `${environment.imageApi}/Resources/Images/Product/${item.imageName}`;
          });
          this._saleProducts$.next(saleProducts);
        })
      );
    return result;
  }



}


