<!--modal popup start-->
<ng-template class="theme-modal" #ageVerification let-modal>
	<div class="modal-content">
        <div class="modal-body modal13">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="age-content">
                                <h2> Age verification</h2>
                                <h4>This Website requires you to be 18 years or older to enter. Please enter your Date Of Birth in this fields below in order to continue:</h4>
                                <form id="demoForm">
                                    <div>
                                        <input type="number" name="day" id="birthDay" 
                                        [formControl]="ageVerificationForm.controls['day']" value="" class="form-control" placeholder="DD" min="1" max="2">
                                        <input type="number" name="month" id="birthMonth" value="" 
                                        [formControl]="ageVerificationForm.controls['month']" class="form-control" placeholder="MM" min="1" max="2">
                                        <input type="number" name="year" id="birthYear" value="" 
                                        [formControl]="ageVerificationForm.controls['year']" class="form-control" placeholder="YY" min="1" max="4">
                                    </div>
                                    <button type="submit" (click)="ageForm()" class="btn btn-solid">
                                    	submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->