import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public _categoryQuery$: BehaviorSubject<string| null>= new BehaviorSubject(null);
  public _subCategoryQuery$: BehaviorSubject<string| null>= new BehaviorSubject(null);
  public _brandQuery$: BehaviorSubject<string| null>= new BehaviorSubject(null);


  
  

  get getCategoryQuery$(): Observable<string>
  {
      return this._categoryQuery$.asObservable();
  }


  
  get getSubCategoryQuery$(): Observable<string>
  {
      return this._subCategoryQuery$.asObservable();
  }

  
  get getBrandQuery$(): Observable<string>
  {
      return this._brandQuery$.asObservable();
  }

  constructor() { }

  
  categoryQuery(name:string ): Observable<string>
  {
    const category:Observable<string> = new Observable( () => {
     this._categoryQuery$.next(name)
   })
   return category;
  }


  subCategoryQuery(name:string ): Observable<string>
  {
    const category:Observable<string> = new Observable( () => {
     this._subCategoryQuery$.next(name)
   })
   return category;
  }

  brandQuery(name:string ): Observable<string>
  {
    const category:Observable<string> = new Observable( () => {
     this._brandQuery$.next(name)
   })
   return category;
  }

}
