import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ShopComponent } from "src/app/shop/shop.component";
import { environment } from "src/environments/environment";
import { IIndexOrder } from "../Models/IOrder";
import {
  IIndexShipping,
  IShipping,
  IShippingConfirm,
  ShippingStatus,
} from "../Models/IShipping";
import {
  getPaginationHeaders,
  getPaginationResult,
} from "../Pagination/getPaginationResult";
import { IExtendPagination, PaginatedResult } from "../Pagination/IPagination";
import * as moment from "moment";

const state = {
  checkoutItems: JSON.parse(localStorage["checkoutItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ShippingService {
  private baseUrl = `${environment.api}/Shipping`;

  private _shippings$: BehaviorSubject<PaginatedResult<IIndexShipping[]> | null> = new BehaviorSubject(null);
  public _shipping$: BehaviorSubject<IIndexShipping | null> =
    new BehaviorSubject(null);

  get getShippings$(): Observable<PaginatedResult<IIndexShipping[]>> {
    return this._shippings$.asObservable();
  }

  get getShipping$(): Observable<IIndexShipping> {
    return this._shipping$.asObservable();
  }

  constructor(
    private router: Router,
    private datePipe: DatePipe,

    private _httpClient: HttpClient
  ) { }

  shippingsList(
    pramas?: IExtendPagination
  ): Observable<PaginatedResult<IIndexShipping[]>> {
    const params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);
    return this.loadShippings(params);
  }

  private loadShippings(
    params: HttpParams
  ): Observable<PaginatedResult<IIndexShipping[]>> {
    const result = getPaginationResult<IIndexShipping[]>(
      `${this.baseUrl}`,
      params,
      this._httpClient
    ).pipe(
      tap((shippings) => {
        this._shippings$.next(shippings);
      })
    );
    return result;
  }

  searchShipping(
    pramas?: IExtendPagination
  ): Observable<PaginatedResult<IIndexShipping[]>> {
    let params = getPaginationHeaders(pramas.currentPage, pramas.pageSize);

    if (pramas.searchTerm && pramas.searchTerm !== "") {
      params = params.append("searchTerm", pramas.searchTerm.toString());
    }

    if (pramas.fromDate && pramas.fromDate !== "") {
      params = params.append("fromDate", pramas.fromDate.toString());
      params = params.append("toDate", pramas.toDate.toString());
    }

    if (pramas.buyerId && pramas.buyerId.toString() !== "") {
      params = params.append("buyerId", pramas.buyerId.toString());
    }

    if (pramas.orderBy && pramas.orderBy.toString() !== "") {
      params = params.append("orderBy", pramas.orderBy.toString());
    }

    return this.loadShippings(params);
  }

  shippingIdAsync(
    buyerId: string,
    shippingId: number
  ): Observable<IIndexShipping> {
    return this._httpClient
      .get<IIndexShipping>(`${this.baseUrl}/${buyerId}/${shippingId}`)
      .pipe(
        map((shipping) => {
          // shipping.imageUrl = `${environment.imageApi}/Resources/Images/shipping/${shipping.imageUrl}`;

          // To set two dates to two variables
          //   var date1 = new Date("06/30/2019");
          //   var date2 = new Date("07/30/2019");

          //   var Time = date2.getTime() - date1.getTime();
          //   var Days = Time / (1000 * 3600 * 24); //Difference in Days


          if (shipping.purchaseDate) {
            var date = new Date();
            var currentDateValue = date.toDateString().slice(0, 10).split("-").reverse().join("/");
            var getCurrentDate = moment(currentDateValue, "DD-MM-YYYY").toDate();
            var timeCurrent = getCurrentDate.getTime();

            var dateString = shipping.purchaseDate.toString();
            var purchaseDateValue = dateString.slice(0, 10).split("-").reverse().join("/");
            const getPurchaseDate = moment(purchaseDateValue, "DD-MM-YYYY").toDate();
            var timePurchase = getPurchaseDate.getTime();


            var Time = timeCurrent - timePurchase;
            var Days = Time / (1000 * 3600 * 24); //Difference in Days


            if (Days > 7 && shipping.shippingStatus == 5) {
              shipping.shippingStatus = ShippingStatus.WarehouseDelivered;
            }

            if (Days > 4 && shipping.shippingStatus == 5 && shipping.expressShipping == true) {
              shipping.shippingStatus = ShippingStatus.WarehouseDelivered;
            }

          }





          shipping.imageUrl = `${environment.imageApi}/${shipping.imageUrl}`;

          this._shipping$.next(shipping);
          return shipping;
        })
      );
  }

  addShipping(data: FormData): Observable<IIndexShipping> {
    return this._httpClient.post<IIndexShipping>(
      `${this.baseUrl}/AddBuyerShipping`,
      data
    );
  }

  updateShipping(data: IIndexShipping): Observable<IIndexShipping> {
    return this._httpClient.put<IIndexShipping>(`${this.baseUrl}`, data);
  }

  deleteShippingItem(orderItemId: number): Observable<IIndexShipping> {
    return this._httpClient.delete<IIndexShipping>(
      `${this.baseUrl}/${orderItemId}`
    );
  }

  UpdateShippingConfirm(data: IShippingConfirm): Observable<IIndexShipping> {
    return this._httpClient
      .put<IIndexShipping>(
        `${this.baseUrl}/UpdateShippingConfirm/${data.shippingId}`,
        data
      )
      .pipe(
        map((shipping) => {
          shipping.imageUrl = `${environment.imageApi}/${shipping.imageUrl}`;
          this._shipping$.next(shipping);
          return shipping;
        })
      );
  }
}
