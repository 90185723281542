import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import Cropper from "cropperjs";
import { IAddBaseItem, IUsaProduct } from 'src/app/shared/Models/TopCollectionProduct';
import { HomeService } from 'src/app/shared/services/home.service';
import { IUser } from 'src/app/shared/Models/IUser';
import { AuthService } from 'src/app/shared/services/account.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IProductVariation } from 'src/app/shared/Models/IProductVariation';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: IUsaProduct;
  // @Input() topCollection: ITopCollection;



  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;


  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  isWholeSaleAccount = 'False';
  user: IUser;
  isLanguageInKurdish = false;


  public ImageSrc: string

  constructor(private productService: ProductService,
    private _router: Router,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _homeService: HomeService) {

    var languageInKurdish = localStorage.getItem("code");
    if (languageInKurdish) {
      this.isLanguageInKurdish = true;
    }

    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      this.isWholeSaleAccount = this.user.isWholeSaleAccount.toString();
    }


  }

  displayImage(imagePath: string) {
    // this.sliders.map(
    //   value=>{
    //     value.imageName = `${environment.imageApi}/Resources/Images/slider/${value.imageName}`;
    //   }
    // )
  }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }


  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }




  colorVariations(variants: IProductVariation[]) {
    const uniqColor = [];
    for (let i = 0; i < variants.length; i++) {
      const element = variants[i];
      uniqColor.push(element.childColorName);
    }
    return uniqColor
  }




  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  // addToCart(product: any) {
  //   this.productService.addToCart(product);
  // }

  addToWishlist(product: any) {
    // this.productService.addToWishlist(product);

    let isUserLogin = false;
    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user || user != null) {
      isUserLogin = true;
    }


    this._authService.getCurrentUser$.subscribe((user: IUser) => {
      if (!user || user == null) {
        isUserLogin = true;
      }
    });


    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      this._router.navigate(['/pages/login'])
      return 0;
    }


    if (!isUserLogin) {
      this._router.navigate(['/pages/login'])
      return;
    }

    product.stock = product.quantity;



    let cartItem: IAddBaseItem = {
      barcode: product.barcode,
      quantitiesInCartItem: 1,
      tableName: "WishListItem"

    }

    this._homeService.addBaseItem(cartItem).subscribe({
      next: () => {
        this._toastrService.success('Product has been added in wishlist.');
      },
      error: () => { },
      complete: () => { console.log('completed') }
    });
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }


  // Add to cart
  async addToCart(product: IUsaProduct) {


    let isUserLogin = false;
    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user || user != null) {
      isUserLogin = true;
    }


    this._authService.getCurrentUser$.subscribe((user: IUser) => {
      if (!user || user == null) {
        isUserLogin = true;
      }
    });


    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      this._router.navigate(['/pages/login'])
      return 0;
    }


    if (!isUserLogin) {
      this._router.navigate(['/pages/login'])
      return;
    }

    product.stock = product.quantity;
    var isPass = this._homeService.calculateStockCounts(product, 1);
    if (!isPass) {
      this._router.navigate(['/shop/cart'])
      return
    }


    let cartItem: IAddBaseItem = {
      barcode: product.barcode,
      quantitiesInCartItem: 1,
      tableName: "CartItem"

    }

    this._homeService.addBaseItem(cartItem).subscribe({
      next: () => {



        if (user.isWholeSaleAccount.toString() == "True") {
          this._router.navigate(['/shop/cart']).then(() => {
            window.location.reload();
          });;

        } else {
          this._router.navigate(['/shop/cart']);
        }

      },
      error: () => { },
      complete: () => { console.log('completed') }
    });

    // product.quantity = this.counter || 1;
    // const status = await this.productService.addToCart(product);
    // if(status)
    //   this.router.navigate(['/shop/cart']);
  }

  isSetWholeSale(value: any) {
    return value;
  }

  kurdishFormat(): Object {


    if (this.isLanguageInKurdish) {
      return {
        "margin-right": "40%",
      };
    }
    return {
      "margin-left": "40%",
    };
  }

  setStyle(color) {
    if (color == 'White') {
      return { 'border': '1px solid black', 'opacity': 1 }
    }

    return { 'border': '' }

  }

}
