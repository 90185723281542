<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li>
                                <div class="">
                                    <ul>
                                        <li>
                                            <a (click)="openLink('https://www.facebook.com/usaonlinebazaar')"
                                                style="cursor: pointer;"><i class="fa fa-facebook"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                        </li> -->
                                        <li>
                                            <a (click)="openLink('https://www.instagram.com/usa_bazaar/')"
                                                style="cursor: pointer; margin-left: -20px;"><i class="fa fa-instagram"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a (click)="openLink('https://www.instagram.com/usa_bazaar_beauty/')"
                                                style="cursor: pointer; margin-left: -20px;"><i class="fa fa-instagram"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a (click)="openLink('https://www.youtube.com/channel/UCtJdrqY89ZlXqwTB44Z97fQ/')"
                                                style="cursor: pointer; margin-left: -20px;"><i class="fa fa-youtube"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a (click)="openLink('https://vm.tiktok.com/TTPdArTjjy/')"
                                                style="cursor: pointer; margin-left: -20px;  color: red;">

                                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img"
                                                    width="0.88em" height="1em" preserveAspectRatio="xMidYMid meet"
                                                    viewBox="0 0 448 512">
                                                    <path fill="currentColor"
                                                        d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121.18 121.18 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z" />
                                                </svg>

                                            </a>
                                        </li>
                                        <li>
                                            <a (click)="openLink('https://www.snapchat.com/add/usa_bazaar?share_id=QzkwNTFF&locale=en_US/')"
                                                style="cursor: pointer; margin-left: -20px;"><i class="fa fa-snapchat"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                        </li> -->
                                    </ul>
                                </div>
                            </li>
                            <!-- <li><i class="fa fa-phone" aria-hidden="true"></i>{{'Call Us: 6009 - 816 - 0750'|translate}}</li> -->
                            <li>{{'Call Us: 0750-816-6009'|translate}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-right">
                    <ul class="header-dropdown">

                        <!-- <li class="compare">
                               <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
                        </li> -->

                        <li *ngIf="mobileScreen">
                            <!-- <a (click)="onChangeEventFunc()"><i class="fa fa-th-lis"
                                    aria-hidden="true"></i>{{'Wholesale'|translate}}</a> -->
                            <a (click)="onChangeEventFunc()" aria-hidden="true">
                                <img src="assets/images/icon/wholesale2.png" style="height: 15px;" class="img-fluid"
                                    alt="Wholesale"> {{'Wholesale'|translate}}
                            </a>
                        </li>


                        <li class="compare">
                            <a [routerLink]="['/shop/usaMarkets']"><i class="fa fa-random"
                                    aria-hidden="true"></i>{{'Shopping In US'|translate}}</a>
                        </li>


                        <li class="" *ngIf="mobileScreen">
                            <a [routerLink]="['/shop/onlineMarket']"><i class="fa fa-plus-square-o"
                                    aria-hidden="true"></i>{{'New Order'|translate}}</a>
                        </li>


                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart"
                                    aria-hidden="true"></i>{{'Wishlist'|translate}}</a>
                        </li>



                        <li class="onhover-dropdown mobile-account ">
                            <i class="fa fa-user" (click)="openHover()" aria-hidden="true"></i>
                            <span *ngIf="(_loginUser$ |async) as user"> {{'Hi'|translate}} {{fullName}} </span>
                            <span *ngIf="!(_loginUser$ |async) "> {{'Account'|translate}} </span>
                            <ul class="onhover-show-div">
                                <ng-container *ngIf="(_loginUser$ |async) ===null">
                                    <li>
                                        <!-- <a data-lng="en" [routerLink]="['/pages/login']"> Login </a> -->
                                        <a style="cursor: pointer;" data-lng="en"
                                            (click)="login()">{{'Login'|translate}} </a>
                                    </li>
                                    <li>
                                        <!-- <a data-lng="en" [routerLink]="['/pages/login']"> Login </a> -->
                                        <!-- <a style="cursor: pointer;" data-lng="en" [routerLink]="['/pages/register']"> Sing up </a> -->
                                    </li>
                                </ng-container>

                                <ng-container *ngIf="(_loginUser$ |async) as user">
                                    <!-- <pre>
                                        {{user |json}}
                                    </pre> -->

                                    <li>
                                        <a data-lng="es"> {{fullName}} </a>
                                    </li>
                                    <li>
                                        <a data-lng="es"
                                            [ngStyle]="{'color': (totalBalance$|async)<0 ? 'red' : 'none' }">{{'Balance'|translate}}
                                            : ${{(totalBalance$|async) |number:'1.0-0'}}
                                        </a>
                                    </li>
                                    <li>
                                        <a data-lng="es"> {{user.accountStatus}} </a>
                                    </li>

                                    <li>
                                        <a data-lng="es">{{'Rewards'|translate}} : ${{reward |number:'1.0-1'}} </a>
                                    </li>

                                    <li>
                                        <a data-lng="es" [routerLink]="['/pages/usaOrders']"> {{'My Orders' |translate}}
                                        </a>
                                    </li>

                                    <li>
                                        <a data-lng="es" [routerLink]="['/pages/dashboard']"> {{'Update Profile'
                                            |translate}} </a>
                                    </li>

                                    <li>
                                        <a data-lng="es" (click)="singOut()">{{'Logout' |translate}} </a>
                                    </li>
                                </ng-container>


                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <!-- <app-left-menu></app-left-menu> -->
                        <div class="brand-logo">
                            <!-- <a routerLink="/home" id="headerlogo"> -->
                            <a (click)="onHomePage()" id="headerlogo" style="cursor: pointer;">
                                <img [src]="themeLogo" [ngStyle]="{'margin-right':onMobileDisplay()}"
                                    style="height: 45px;" class="img-fluid" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div>
                            <app-menu></app-menu>
                        </div>
                        <div>








                            <!-- <h6 style="color: red;">Please Check App Setting header one to active app-setting </h6> -->
                            <app-settings></app-settings>





                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>