import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IIndexCustomer } from '../../Models/ICustomer';
import { IUser } from '../../Models/IUser';
import { IExtendPagination } from '../../Pagination/IPagination';
import { AuthService } from '../../services/account.service';
import { CategoryService } from '../../services/Category.service';
import { CustomerService } from '../../services/Customer.service';
import { HomeService } from '../../services/home.service';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit, OnDestroy {


  supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  supportsPassiveEventListeners = supportsPassiveEventListeners();
  supportsScrollBehavior = supportsScrollBehavior();

  public search: boolean = false;

  searchProductInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/usaBazaarLogo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  reward = 0;
  fullName = '';
  customer$: Observable<IIndexCustomer>;
  totalBalance$: Observable<number>;
  totalBalance = 0;
  mobileScreen = true;


  public stick: boolean = false;
  isLogin: boolean = false;

  public _loginUser$: Observable<IUser>;


  pagination: IExtendPagination;

  andriodDevice = false;
  iOSDevice = false;
  mobileCheck = false;
  constructor(
    private _activatedRoute: ActivatedRoute,
    public _authService: AuthService,
    public _homeService: HomeService,
    private viewScroller: ViewportScroller,
    private _formBuilder: FormBuilder,
    private _categoryService: CategoryService,
    private _customerService: CustomerService,
    public platform: Platform,
    private _router: Router
  ) {
    this._loginUser$ = this._authService.getCurrentUser$;


    this._loginUser$.subscribe({
      next: (_user): void => {
        this.isLogin = true;

      },
      error: (_error) => {
        this.isLogin = false
      },
      complete: () => { }
    })
  }

  marginRight = '0px';
  searchToggle() {
    this.search = !this.search;
  }


  detectMob() {
    const toMatch = [
      /Android/i,
      /iPhone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    })
  };

  ngOnInit(): void {


    this.mobileCheck = this.detectMob();




    if (window.screen.width <= 390 || this.mobileCheck) { // 768px portrait
      this.mobileScreen = false;

    } else {
      this.mobileScreen = true;
    }



    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.customer$ = this._customerService.getCustomer$;
      this.customer$.subscribe((data => {
        if (!data) {
          this._customerService.customerByIdInServer(user.id).subscribe((customer: IIndexCustomer) => {
            //   localStorage.setItem('reward',customer.reward.toString())
          });

          this._customerService.getTotalBalance(user.id).subscribe();
          this.totalBalance$ = this._customerService.getTotalBalance$;
          this.totalBalance$.subscribe((value: number) => {


            this.totalBalance = value;
          })

          return;
        }
        this.reward = data.reward;
        this.fullName = data.fullName;
      }))

    }




    // var user:IUser = JSON.parse(localStorage.getItem('user'));
    // if(user)
    // {
    //   this.reward = +localStorage.getItem('reward');
    //   this.fullName = localStorage.getItem('fullName');
    // }



    // Subscribe to search input field value changes
    this.searchProductInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((value) => {
          this.pagination = {
            currentPage: 1,
            pageSize: 24,
            searchTerm: value ? value : '',
          };
          return this.pagination;

        }),
        debounceTime(1000),
        switchMap((paramters: IExtendPagination) => {

          let params = {
            category: null,
            subCategory: null,
            brand: null,
            orderBy: null,
            filtered: null,
            searchTerm: null
          }

          this._router.navigate([], {
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
          }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
          });

          paramters.searchTerm = paramters.searchTerm.trim();

          if (paramters.searchTerm) {
            return this._router.navigateByUrl(`/shop/product?searchTerm=${paramters.searchTerm}`)
          } else {
            return this._router.navigateByUrl(`/shop/product`)

          }
        })
      )
      .subscribe((products) => {

      });




  }

  singOut() {
    this._homeService._cartItems$.next(null);
    localStorage.removeItem('reward');
    localStorage.removeItem('user');
    localStorage.removeItem('fullName');
    this._authService.signOut();
    this._router.navigate(['/'])
  }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }


  public onDisplayImage = (serverPath: string) => {
    let imageApi = environment.imageApi;
    var result = `${imageApi}/${serverPath}`;

    return result;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  login() {
    let routerStateSnapshot = this._router.routerState.snapshot;
    this._router.navigate(['/pages/login'], { queryParams: { 'redirectURL': routerStateSnapshot.url } });

  }

  register() {
    let routerStateSnapshot = this._router.routerState.snapshot;
    this._router.navigate(['/pages/register'], { queryParams: { 'redirectURL': routerStateSnapshot.url } });
  }

  openLink(link: string) {
    window.open(link)

  }

  onHomePage() {


    this._router.navigate(['/home']).then(() => {
      window.location.reload();
    });

  }


  onChangeEventFunc() {

    this.pagination = {
      currentPage: 1,
      pageSize: 50,
      category: undefined,
      subCategory: undefined,
      brand: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      orderBy: undefined,
      searchTerm: undefined,
      filtered: undefined,
      buyerId: undefined,
      wholesalerAccount: 1,
    };



    this._categoryService._categoryQuery$.next(null);
    this._categoryService._subCategoryQuery$.next(null);
    this._categoryService._brandQuery$.next(null);





    let params = {
      category: null,
      subCategory: null,
      brand: null,
      orderBy: null,
      filtered: null,
      searchTerm: null
    }



    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });

    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user && user.isWholeSaleAccount.toString() == "True") {
      this._router.navigateByUrl(`/shop/product?wholesalerAccount=1`)
    } else {
      this._router.navigateByUrl(`/shop/wholesaleLogin`)

    }


  }


  public openAccount: boolean = false;
  // Toggle Mobile Sidebar
  openHover() {
    this.openAccount = !this.openAccount;
  }

  onMobileDisplay() {



    if (window.screen.width <= 390 || this.mobileCheck) { // 768px portrait
      return "200px"

    }
    return "0px";

  }
}
