import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { IBaseItem } from '../../Models/TopCollectionProduct';
import { HomeService } from '../../services/home.service';
import { IUser } from '../../Models/IUser';
import { AuthService } from '../../services/account.service';
import { IExtendPagination } from '../../Pagination/IPagination';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { CategoryService } from '../../services/Category.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {



  searchProductInputControl: FormControl = new FormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public products: Product[] = [];
  cartItems$: Observable<IBaseItem[]>;
  pagination: IExtendPagination;

  public _loginUser$: Observable<IUser>;
  public search: boolean = false;
  isLogin: boolean = false;
  totalPrice = 0;

  public languages = [{
    name: 'English',
    code: 'en'
  },
  //  {
  //   name: 'French',
  //   code: 'fr'
  // },
  {
    name: 'Kurdish',
    code: 'ku'
  }
  ];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private _homeService: HomeService,
    private _router: Router,
    private translate: TranslateService,
    public _activatedRoute: ActivatedRoute,
    private viewScroller: ViewportScroller,
    private _categoryService: CategoryService,
    private _navService: NavService,
    public _authService: AuthService,
    public productService: ProductService) {
    this.productService.cartItems.subscribe(response => this.products = response);

  }

  ngOnInit(): void {

    this.cartItems$ = this._homeService.getCartItems$;
    this.cartItems$.subscribe((items: IBaseItem[]) => {

      if (items && items.length > 0) {
        this.getTotalPrice(items);
      }
    })


    this._loginUser$ = this._authService.getCurrentUser$;

    this._loginUser$.subscribe({
      next: (_user): void => {
        this.isLogin = true;
      },
      error: (_error) => {
        this.isLogin = false
      },
      complete: () => { }
    })


    if (!this.isLogin) {
      var user: IUser = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this._authService._currentUserSource$.next(user);
      }
    }


    this.searchProductInputControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        map((value) => {
          this.pagination = {
            currentPage: 1,
            pageSize: 24,
            searchTerm: value ? value : '',
          };
          return this.pagination;

        }),
        debounceTime(7000),
        switchMap((paramters: IExtendPagination) => {


          this._categoryService._categoryQuery$.next(null);
          this._categoryService._subCategoryQuery$.next(null);
          this._categoryService._brandQuery$.next(null);

          let params = {
            category: null,
            subCategory: null,
            brand: null,
            orderBy: null,
            filtered: null,
            searchTerm: paramters.searchTerm.trim() ? paramters.searchTerm.trim() : ''
          }



          this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
          }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
          });





          if (paramters.searchTerm) {
            return this._router.navigateByUrl(`/shop/product?searchTerm=${paramters.searchTerm}`)
          } else {
            return this._router.navigateByUrl(`/shop/product`)

          }
        })
      )
      .subscribe((products) => {

      });




  }










  getTotalPrice(cartItems: IBaseItem[]) {
    this.totalPrice = 0;

    this.totalPrice = cartItems.reduce((prev, curr: IBaseItem) => {

      let price = curr.wholeSalePrice > 0
        ? curr.wholeSalePrice : curr.newPrice > 0
          ? curr.newPrice : curr.unitPrice;
      return prev + price * curr.quantitiesInCartItem;
    }, 0);

  }



  searchToggle() {
    this.search = !this.search;


    // if(this.search == true)
    // {

    //   let params = { 
    //     category:null, 
    //     subCategory:null, 
    //     brand:null,
    //     orderBy:null,
    //     filtered:null,
    //     searchTerm: null
    //   }

    //   this._router.navigate([], { 
    //     queryParams: null,
    //     queryParamsHandling: 'merge', // preserve the existing query params in the route
    //     skipLocationChange: false  // do trigger navigation
    //   }).finally(() => {
    //     this.viewScroller.setOffset([120, 120]);
    //     this.viewScroller.scrollToAnchor('products'); // Anchore Link
    //   });

    // }

  }


  changeLanguage(code) {

    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
      if (code == 'ku') {
        document.body.classList.remove('ltr')
        document.body.classList.add('rtl')
        localStorage.setItem('code', 'ku');
        this._navService.loadMenus().subscribe();
      } else {
        document.body.classList.remove('rtl')
        document.body.classList.add('ltr')
        localStorage.removeItem('code')
        this._navService.loadMenus().subscribe();

      }


    }
  }

  // get getTotal(): Observable<number> {
  //   var user:IUser = JSON.parse(localStorage.getItem('user'));
  //   if(user)
  //   {
  //     return this._homeService.cartTotalAmount();
  //   }
  //   return of(0);

  // }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  onSearch(value: any) {

    this.pagination = {
      currentPage: 1,
      pageSize: 24,
      searchTerm: value ? value : '',
    };


    this._categoryService._categoryQuery$.next(null);
    this._categoryService._subCategoryQuery$.next(null);
    this._categoryService._brandQuery$.next(null);

    let params = {
      category: null,
      subCategory: null,
      brand: null,
      orderBy: null,
      filtered: null,
      searchTerm: this.pagination.searchTerm.trim() ?
        this.pagination.searchTerm.trim() : ''
    }



    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });





    if (this.pagination.searchTerm) {
      return this._router.navigateByUrl(`/shop/product?searchTerm=${this.pagination.searchTerm}`);
    } else {
      return this._router.navigateByUrl(`/shop/product`);

    }







    // this._homeService.searchBrands("noValue", this.pagination).subscribe();
  }


  onOpenCart() {
    this._router.navigateByUrl('/shop/cart')
      .then(() => {
        document.body.scrollTop = 0;
      });
  }

  onOpenCheckout() {
    this._router.navigateByUrl('/shop/checkout')
      .then(() => {
        document.body.scrollTop = 0;
      });
  }


  onChangeEventFunc() {

    this.pagination = {
      currentPage: 1,
      pageSize: 50,
      category: undefined,
      subCategory: undefined,
      brand: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      orderBy: undefined,
      searchTerm: undefined,
      filtered: undefined,
      buyerId: undefined,
      wholesalerAccount: 1,
    };



    this._categoryService._categoryQuery$.next(null);
    this._categoryService._subCategoryQuery$.next(null);
    this._categoryService._brandQuery$.next(null);





    let params = {
      category: null,
      subCategory: null,
      brand: null,
      orderBy: null,
      filtered: null,
      searchTerm: null
    }



    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });

    var user: IUser = JSON.parse(localStorage.getItem('user'));
    if (user && user.isWholeSaleAccount.toString() == "True") {
      this._router.navigateByUrl(`/shop/product?wholesalerAccount=1`)
    } else {
      this._router.navigateByUrl(`/shop/wholesaleLogin`)

    }
  }


  onFlagLangauge() {

    var currentAppLanguage = localStorage.getItem('code')
    if (currentAppLanguage == 'ku') {
      return "assets/images/icon/ku_flag.png";
    } else {
      return "assets/images/icon/en_flag.png";
    }
  }
}
